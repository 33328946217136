import { Get, getAPIUrl, Post } from "../../../../ApiServices/apiMethod";
import { HotelEndpoint } from "./HotelEndpoint";

export const countryList = (param = "") => {
  const url = getAPIUrl(HotelEndpoint.getCountryList, param);
  return Get(url);
};
export const destinationList = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.getDestination, param);
  return Post(url, data);
};
export const topDestination = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.topDestination, param);
  return Post(url, data);
};
export const hotelStaticData = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.getHotelStaticData, param);
  return Post(url, data);
};
export const HotelSearch = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelSearch, param);
  return Post(url, data);
};
export const Hotelinfo = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelInfo, param);
  return Post(url, data);
};
export const HotelRoom = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelRoom, param);
  return Post(url, data);
};
export const HotelBlock = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelBlockRoom, param);
  return Post(url, data);
};
export const HotelBook = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelBookRoom, param);
  return Post(url, data);
};
export const HotelBookingDetail = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelBookingDetail, param);
  return Post(url, data);
};

export const GetHotelReport = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelReport, param);
  return Post(url, data);
};
export const HotelSendCancelReq = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelSendCancelReq, param);
  return Post(url, data);
};
export const HotelGetChangeReq = (data, param = "") => {
  const url = getAPIUrl(HotelEndpoint.hotelGetChangeReq, param);
  return Post(url, data);
};

export const DownloadTicket = ( param = "") => {
  const url = getAPIUrl(HotelEndpoint.ticketPrint, param);
  return Post(url);
};