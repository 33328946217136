import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import { busDetail } from "./BusFun";

const PickupPoint = ({ detail, selectedLocation, setSelectedLocation }) => {
  const [data, setData] = useState(null);
  const [pageLoader, setPageLoader] = useState(false);
  const getBusDetail = () => {
    setPageLoader(true);
    busDetail({ result_index: detail.resultIndex, trace_id: detail.traceId })
      .then((res) => {
        if (res.status) {
          setData(res.data);
          setSelectedLocation({
            ...selectedLocation,
            boardingLength: res.data?.boardingPointsDetails
              ? res.data?.boardingPointsDetails.length
              : 0,
            droppingLength: res.data?.droppingPointsDetails
              ? res.data?.droppingPointsDetails.length
              : 0,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  useEffect(() => {
    getBusDetail();
  }, []);
  return (
    <>
      {pageLoader ? (
        <div className="grid grid-cols-2 pb-28 place-items-center mt-4 gap-x-4">
          <div class="h-[400px] mt-4 animate-pulse bg-gray-400 rounded-md dark:bg-gray-400 w-full "></div>{" "}
          <div class="h-[400px] mt-4 animate-pulse bg-gray-400 rounded-md dark:bg-gray-400 w-full "></div>
        </div>
      ) : (
        <div className="grid grid-cols-2 mt-4 place-items-start gap-x-3">
          <div className="border w-full rounded-lg border-gray-400 p-2">
            <p>Pickup Points</p>
            <div className="mt-2">
              {data?.boardingPointsDetails?.map((i, index) => {
                return (
                  <div className="mb-4">
                    <Radio
                      checked={
                        selectedLocation.BoardingPointId === i.cityPointIndex
                      }
                      value={i?.cityPointIndex}
                      onChange={(val) =>
                        setSelectedLocation({
                          ...selectedLocation,
                          BoardingPointId: val.target.value,
                          boardingPoint: i?.cityPointName,
                        })
                      }
                    >
                      <div>
                        <p className="font-bold"> {i?.cityPointLocation}</p>
                        <p className="text-xs text-gray-500">
                          {i?.cityPointLandmark}
                        </p>
                        <p className="text-xs mt-1">
                          {i?.cityPointContactNumber}
                        </p>
                        <p className="text-xs mt-1">
                          {new Date(i?.cityPointTime).toLocaleString()}
                        </p>
                      </div>
                    </Radio>
                    {index !== data?.boardingPointsDetails?.length - 1 && (
                      <hr className="my-2" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="border w-full rounded-lg border-gray-400 p-2">
            <p>Drop-Off Points</p>
            <div className="mt-2">
              {data?.droppingPointsDetails?.map((i, index) => {
                return (
                  <div className="mb-4">
                    <Radio
                      checked={
                        selectedLocation.DropingPointId === i.cityPointIndex
                      }
                      value={i?.cityPointIndex}
                      onChange={(val) =>
                        setSelectedLocation({
                          ...selectedLocation,
                          DropingPointId: val.target.value,
                          droppingPoint: i?.cityPointName,
                        })
                      }
                    >
                      <div>
                        <p className="font-bold"> {i?.cityPointName}</p>
                        <p className="text-xs text-gray-500">
                          {i?.cityPointLocation}
                        </p>
                        <p className="text-xs mt-1">
                          {new Date(i?.cityPointTime).toLocaleString()}
                        </p>
                      </div>
                    </Radio>
                    {index !== data?.droppingPointsDetails?.length - 1 && (
                      <hr className="my-2" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PickupPoint;
