import { Button, Drawer } from "antd";
import React from "react";
import { ThemeColor } from "../Theme/theme";
import { useRef } from "react";
import { getCurrentTime } from "../Utils";
import { Images } from "../Controller";
import {useReactToPrint} from "react-to-print";
const ReportDrawer = ({
  open = true,
  foryes=false,
  setOpen = null,
  content = "",
  title = "",
}) => {
  const reportTemplateRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => reportTemplateRef.current,
  });
  return (
    <Drawer
      maskClosable={false}
      headerStyle={{
        background: ThemeColor.primary,
        color: "white",
      }}
      className="drawer"
      style={{ background: "white" }}
      title={title}
      placement="right"
      onClose={() => setOpen(false)}
      open={open}
    >
      <div
        ref={reportTemplateRef}
        className=" bg-white w-full m-auto py-4 flex flex-col justify-center items-center"
      >
        {foryes?
         <div className="flex justify-end gap-2 items-center">
           <img
              src={Images.WebsiteLogo}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
             <img
              src={Images.yesBank}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
           </div>:
       <img src={Images.WebsiteLogo} alt="weblogo" className="mb-2"/>
        }
        <p className="text-[11px]">{getCurrentTime(new Date())}</p>
        {content}
        <p className="text-xs mt-4 text-gray-500">Thanks</p>
      </div>
      <div className="mt-2 flex justify-center items-center">
        <Button
          onClick={handlePrint}
          className="bg-blue-600 text-white border-none mt-2"
        >
          Print
        </Button>
      </div>
    </Drawer>
  );
};

export default ReportDrawer;
