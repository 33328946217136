import React, { useEffect, useState } from "react";
import { busBookDetail, BusTicketDownload, changeReq } from "./BusFun";
import { Button, Input, Popconfirm, Spin } from "antd";
import { MdCancel } from "react-icons/md";
import { useLocation } from "react-router-dom";

const BusBookingDetail = () => {
  const [bookingDetail, setBookingDetail] = useState(null);
  const [bookingItineary, setBookingItineary] = useState(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [remark, setRemark] = useState("");
  const { bookingId } = useLocation().state || {};
  const getBookingDetail = () => {
    setPageLoader(true);
    busBookDetail({ bus_booking_id: bookingId })
      .then((res) => {
        if (res.status) {
          setBookingDetail(res.data);
          setBookingItineary(res.data.getBookingDetailResult.itinerary);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  useEffect(() => {
    getBookingDetail();
  }, []);
  const handleChangeReq = () => {
    setPageLoader(true);
    const formdata = new FormData();
    formdata.append("bus_booking_id", bookingDetail?.bus_booking_id);
    formdata.append("remarks", remark);
    changeReq(formdata)
      .then((res) => {
        if (res.status) {
          getBookingDetail();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  const handleDownload = () => {
    setPageLoader(true);
    BusTicketDownload(`${bookingId}`)
      .then((res) => {
        if (res.status) {
          console.log(res);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  return (
    <>
      <Spin spinning={pageLoader}>
        {bookingDetail?.status == "1" &&
          bookingDetail?.ticket_status == "2" && (
            <Button
              onClick={handleDownload}
              className="text-white font-semibold bg-red-700 w-[200px]   border-none hover:border-none rounded-none flex justify-center items-center gap-x-2"
            >
              Download Ticket
            </Button>
          )}
        {bookingDetail?.status == "1" && bookingDetail?.ticket_status == "2" ? (
          <div className="flex justify-end items-center w-full px-4">
            <Popconfirm
              onConfirm={() => {
                handleChangeReq();
              }}
              cancelButtonProps={{
                className: "text-black hover:text-black",
                type: "primary",
              }}
              okButtonProps={{
                className: "text-white bg-red-700",
                type: "primary",
              }}
              title={
                <p className="font-semibold">
                  Are you sure you want to cancel complete ticket?
                </p>
              }
              description={
                <div>
                  <Input
                    onChange={(e) => setRemark(e.target.value)}
                    className="border border-gray-400 h-8 text-xs rounded-none"
                    placeholder="Enter Remark"
                  />
                </div>
              }
            >
              <Button className="text-white font-semibold bg-red-700 w-[200px]   border-none hover:border-none rounded-none flex justify-center items-center gap-x-2">
                <MdCancel color="white" />
                Cancel
              </Button>
            </Popconfirm>
          </div>
        ) : bookingDetail?.ticket_status == "3" ? (
          <p className="flex justify-end items-center my-2 text-xl font-serif text-red-500 font-bold">
            Ticket Cancelled
          </p>
        ) : bookingDetail?.status == "2" ||
          bookingDetail?.ticket_status == "4" ? (
          <p className="flex justify-end items-center my-2 text-xl font-serif text-red-500 font-bold">
            Ticket Failed
          </p>
        ) : bookingDetail?.status === "0" ||
          bookingDetail?.ticket_status == "5" ? (
          <p className="flex justify-end items-center my-2 text-xl font-serif text-yellow-500 font-bold">
            Ticket Pending
          </p>
        ) : (
          ""
        )}
        <div className="bg-white p-3 mt-2 rounded-md">
          <p className="font-bold text-lg">Passanger Details</p>
          {bookingItineary?.passenger?.map((i, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-gray-400 rounded-md p-3 place-items-start gap-3 mt-2"
              >
                <p>
                  <span className="font-bold">Name:</span>{" "}
                  {`${i.firstName ?? "--"} ${i.lastName ?? "--"}`}
                </p>
                <p>
                  <span className="font-bold">Email:</span> {i.email ?? "--"}
                </p>
                <p>
                  <span className="font-bold">Age:</span> {i.age ?? "--"}
                </p>
                <p>
                  <span className="font-bold">Address:</span>{" "}
                  {i.address ?? "--"}
                </p>
                <p>
                  <span className="font-bold">Gender:</span>{" "}
                  {i.gender == 1 ? "Male" : "Female" ?? "--"}
                </p>
                <p>
                  <span className="font-bold">Id Type:</span> {i.idType ?? "--"}
                </p>{" "}
                <p>
                  <span className="font-bold">id Number:</span>{" "}
                  {i.idNumber ?? "--"}
                </p>
                <p>
                  <span className="font-bold">Seat Number:</span>{" "}
                  {i.seat.seatName ?? "--"}
                </p>
                <p>
                  <span className="font-bold">IsUpper:</span>{" "}
                  {i.seat.isUpper ? "Yes" : "No"}
                </p>
                <p>
                  <span className="font-bold">Seat Id:</span>{" "}
                  {i.seat?.seatId ?? "--"}
                </p>
              </div>
            );
          })}
          <p className="font-bold text-lg mt-3">Bus Detail</p>
          <div className="border border-gray-400 rounded-md p-3 mt-2">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  place-items-start gap-3 ">
              <p>
                <span className="font-bold">Travel Name:</span>{" "}
                {bookingItineary?.travelName ?? "--"}
              </p>
              <p>
                <span className="font-bold">Travel PNR:</span>{" "}
                {bookingItineary?.travelOperatorPNR ?? "--"}
              </p>
              <p>
                <span className="font-bold">Bus Id:</span>{" "}
                {`${bookingItineary?.busId ?? "--"} `}
              </p>
              <p>
                <span className="font-bold">Bus Type:</span>{" "}
                {bookingItineary?.busType ?? "--"}
              </p>
              <p>
                <span className="font-bold">Date Of Journey:</span>{" "}
                {bookingItineary?.dateOfJourney ?? "--"}
              </p>
              <p>
                <span className="font-bold">Departur Time:</span>{" "}
                {new Date(bookingItineary?.departureTime).toDateString() ??
                  "--"}
              </p>
              <p>
                <span className="font-bold">Arrival Time:</span>{" "}
                {new Date(bookingItineary?.arrivalTime).toDateString() ?? "--"}
              </p>
              <p>
                <span className="font-bold">Origin:</span>{" "}
                {bookingItineary?.origin ?? "--"}
              </p>{" "}
              <p>
                <span className="font-bold">Destination:</span>{" "}
                {bookingItineary?.destination ?? "--"}
              </p>
              <p>
                <span className="font-bold">No Of Seats:</span>{" "}
                {bookingItineary?.noOfSeats ?? "--"}
              </p>
              <p>
                <span className="font-bold">Ticket No:</span>{" "}
                {bookingItineary?.ticketNo ?? "--"}
              </p>
            </div>
            {bookingItineary?.boardingPointdetails && (
              <>
                <p className="font-semibold  mt-4 underline underline-offset-4">
                  Boarding Point Detail
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-gray-200 rounded-md p-3 place-items-start gap-3 mt-2">
                  <p>
                    <span className="font-bold">City Point Address:</span>{" "}
                    {bookingItineary?.boardingPointdetails?.cityPointLocation ??
                      "--"}
                  </p>
                  <p>
                    <span className="font-bold">City Point LandMark:</span>{" "}
                    {bookingItineary?.boardingPointdetails?.cityPointName ??
                      "--"}
                  </p>
                  <p>
                    <span className="font-bold">Contact Number:</span>{" "}
                    {bookingItineary?.boardingPointdetails
                      ?.cityPointContactNumber ?? "--"}
                  </p>
                  <p>
                    <span className="font-bold">City Point Time:</span>{" "}
                    {bookingItineary?.boardingPointdetails?.cityPointTime ??
                      "--"}
                  </p>
                </div>
              </>
            )}

            {bookingItineary?.dropingPointdetails && (
              <>
                <p className="font-semibold  mt-4 underline underline-offset-4">
                  Dropping Point Detail
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-gray-200 rounded-md p-3 place-items-start gap-3 mt-2">
                  <p>
                    <span className="font-bold">City Point Address:</span>{" "}
                    {bookingItineary?.dropingPointdetails?.cityPointLocation ??
                      "--"}
                  </p>
                  <p>
                    <span className="font-bold">City Point LandMark:</span>{" "}
                    {bookingItineary?.dropingPointdetails?.cityPointName ??
                      "--"}
                  </p>
                  <p>
                    <span className="font-bold">Contact Number:</span>{" "}
                    {bookingItineary?.dropingPointdetails
                      ?.cityPointContactNumber ?? "--"}
                  </p>
                  <p>
                    <span className="font-bold">City Point Time:</span>{" "}
                    {bookingItineary?.dropingPointdetails?.cityPointTime ??
                      "--"}
                  </p>
                </div>
              </>
            )}
          </div>

          <p className="font-bold text-lg mt-3">Price Detail</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-gray-200 rounded-md p-3 place-items-start gap-3 mt-2">
            <p>
              <span className="font-bold">Published Fare:</span>{" "}
              {bookingItineary?.price?.publishedPrice ?? "--"}
            </p>
            <p>
              <span className="font-bold">Total Fare:</span>{" "}
              {bookingDetail?.total_fare ?? "--"}
            </p>
          </div>
          <div className="border border-gray-400 p-2 rounded-md mt-3">
            <p className="font-bold text-lg mt-3">Cancellation Policy</p>
            {bookingItineary?.cancelPolicy?.map((i, index) => {
              return (
                <div
                  key={index}
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-gray-200 rounded-md p-3 place-items-start gap-1 mt-2"
                >
                  <p>
                    <span className="font-bold">Published Fare:</span>{" "}
                    {i.cancellationCharge ?? "--"}
                  </p>
                  <p>
                    <span className="font-bold">Round OFF Fare:</span>{" "}
                    {new Date(i.fromDate).toDateString() ?? "--"}
                  </p>
                  <p>
                    <span className="font-bold">Round OFF Fare:</span>{" "}
                    {i.policyString ?? "--"}
                  </p>
                  <p>
                    <span className="font-bold">Round OFF Fare:</span>{" "}
                    {new Date(i.toDate).toDateString() ?? "--"}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default BusBookingDetail;
