import React, { useState, useRef, useEffect } from "react";
import { Images } from "../../Controller";
import { useLocation, useNavigate } from "react-router-dom";
import { sendResetOtp, verifyPin } from "../../ApiServices/Apifun";
import {
  PortalName,
  dispatchtoast,
  getLocalStorageItem,
  messageConfiguration,
  setLocalStorageItem,
} from "../../Utils";
import { ReactComponent as AepsloginIcon } from "../../Assets/logoicons/AEPS.svg";
import { ReactComponent as BbpsloginIcon } from "../../Assets/logoicons/BBPS.svg";
import { ReactComponent as KycloginIcon } from "../../Assets/logoicons/KYC.svg";
import { ReactComponent as PayoutloginIcon } from "../../Assets/logoicons/Payout.svg";
import { ReactComponent as RechargeloginIcon } from "../../Assets/logoicons/Recharge.svg";
import { ReactComponent as TravelloginIcon } from "../../Assets/logoicons/Travel.svg";
import { ReactComponent as WalletloginIcon } from "../../Assets/logoicons/Wallet.svg";
import { Button } from "antd";
import { FaUserTie } from "react-icons/fa";

const Otp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const otpLength = 4;
  const inputRefs = Array.from({ length: otpLength }, () => useRef(null));
  const [pin, setPin] = useState("");
  const handleChange = (index, value) => {
    if (/^[0-9]*$/.test(value)) {
      setPin((prevOTP) => {
        const updatedOTP = [...prevOTP];
        updatedOTP[index] = value;
        return updatedOTP;
      });

      // Move to the next input field if the value is numeric
      if (value !== "" && index < otpLength - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyUp = (index, event) => {
    if (event.keyCode === 8) {
      // Clear the previous input field on Backspace if the current field is empty
      if (index > 0 && pin[index] === "") {
        setPin((prevOTP) => {
          const updatedOTP = [...prevOTP];
          updatedOTP.splice(index, 1);
          return updatedOTP;
        });

        // Move focus back to the previous input field
        inputRefs[index - 1].current.focus();
      }
    }
  };
  const handleVerify = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("mobile", location.state?.mobile);
    formdata.append("password", location.state?.password);
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    formdata.append("pin", pin.join(""));
    verifyPin(formdata)
      .then((res) => {
        if (res.status) {
          if (res.digio_kyc === "0" || res.digio_kyc === "2" || res.digio_kyc === "3") {
            setLocalStorageItem("isLoggedIn", JSON.stringify(true));
            setLocalStorageItem("access_token", res.token);
            navigate("/kycStatus");
          } 
          // else if (res.digio_kyc === "3") {
          //   setLocalStorageItem("isLoggedIn", JSON.stringify(true));
          //   setLocalStorageItem("access_token", res.token);
          //   navigate(`/kycPage?digiokyc=${res.digio_kyc}`, {
          //     state: { digio_kyc: res.digio_kyc },
          //   });
          // } 
          else {
            setLocalStorageItem("isLoggedIn", JSON.stringify(true));
            setLocalStorageItem("access_token", res.token);
            navigate("/dashboard");
          }
        }
        {
          dispatchtoast(res.msg, true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleSendResetOtp = () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("mobile", location.state?.mobile);
    sendResetOtp(formdata)
      .then((res) => {
        if (res.status) {
          navigate("/resetpin", { state: { otp_uid: res.otp_uid } });
          dispatchtoast("OTP send successfully!");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div className="flex justify-center bg-blue-100 h-screen items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full md:w-9/12 h-screen md:h-full  place-items-center">
          <div className="h-full md:h-5/6 shadow-lg shadow-gray-500  bg-primary rounded-tl-lg rounded-bl-lg  w-full px-10 flex flex-col justify-center items-center">
            <p className="mt-10">
              <FaUserTie color="white" size={60} />
            </p>
            <p className="text-white mt-10 font-[100] text-4xl">Welcome to</p>
            <p className="mt-6 text-3xl font-semibold text-white">{PortalName}</p>
            <p className="text-xs font-normal text-white mt-4">
              Bunch of all business solution logic
            </p>
            <img
              src={Images.loginillustration2}
              className="w-[300px] h-[200px] mt-4 m-4"
            />
          </div>
          <div className=" h-full md:h-5/6 shadow-lg shadow-gray-500 rounded-br-xl rounded-tr-xl relative my-16 md:my-0 w-full flex flex-col bg-white justify-center items-center">
            <img
              src={Images.WebsiteLogo}
              style={{ filter: "drop-shadow(0px 1px 2px #cfc5c5)" }}
            />
            <p
              className="text-start mt-4 font-semibold text-xl"
              style={{ fontFamily: "system-ui" }}
            >
              Verify Pin
            </p>
            <div className="  py-10">
              <div className="w-full mt-2 flex justify-center items-center">
                {[1, 2, 3, 4].map((i, index) => {
                  return (
                    <input
                      key={index}
                      ref={inputRefs[index]}
                      type="text"
                      maxLength={1}
                      className="w-[25px] xs:w-[35px] sm:w-[40px] md:w-[50px] text-bsblackrgb bg-gray-300 rounded-md h-8  sm:shadow-md s md:shadow-sm mx-1 outline-none border-none text-center"
                      value={pin[index]}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                    />
                  );
                })}
              </div>
              <p onClick={handleSendResetOtp} className="flex justify-end items-center text-primary mt-2 cursor-pointer">Forgot Pin?</p>
              <Button
                loading={loading}
                onClick={pin.length === 4 && handleVerify}
                className={`SignInBtn w-full ${
                  pin.length === 4 ? "cursor-pointer" : "cursor-not-allowed"
                }  mt-4 ${
                  pin.length === 4 ? "bg-primary" : "bg-bsgray"
                }  flex justify-center border-none items-center h-12 rounded-full text-white text-[12px] font-bold`}
              >
                <p>Verify PIN</p>
              </Button>
            </div>
            <div className="absolute bottom-4  flex justify-center flex-wrap gap-4  w-full items-center  font-semibold text-3xl font-serif">
              <AepsloginIcon className="cursor-pointer" />
              <BbpsloginIcon className="cursor-pointer" />
              <KycloginIcon className="cursor-pointer" />
              <TravelloginIcon className="cursor-pointer" />
              <RechargeloginIcon className="cursor-pointer" />
              <PayoutloginIcon className="cursor-pointer" />
              <WalletloginIcon className="cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
