import { Drawer } from "antd";
import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";

import SeatLayout from "./SeatLayout";
import PickupPoint from "./PickupPoint";
import { useNavigate } from "react-router-dom";

const SelectSeatDrawer = ({ open, setOpen, detail, points }) => {
  const { data, resultIndex, traceId } = detail;
  const naviagte = useNavigate();
  const [currentTab, setcurrentTab] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState({
    BoardingPointId: null,
    boardingPoint: null,
    boardingLength: null,
    DropingPointId: null,
    droppingPoint: null,
    droppingLength: null,
  });
  const [selectedSeat, setSelectedSeat] = useState([]);
  const [overAllPrice, setOverAllPrice] = useState(0);
  console.log(detail);

  const tabs = [
    {
      id: 1,
      title: "Select Seats",
      component: (
        <SeatLayout
          traceId={traceId}
          resultIndex={resultIndex}
          setSelectedSeat={setSelectedSeat}
          setOverAllPrice={setOverAllPrice}
          selectedSeat={selectedSeat}
          overAllPrice={overAllPrice}
        />
      ),
    },
    {
      id: 2,
      title: "Select pick-up & drop-off points",
      component: (
        <PickupPoint
          detail={detail}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
        />
      ),
    },
  ];
  const handleNext = () => {
    const { boardingLength, droppingLength, BoardingPointId, DropingPointId } =
      selectedLocation;

    if (selectedSeat.length === 0) return;

    const isInvalidSelection =
      (boardingLength > 0 && !BoardingPointId) ||
      (droppingLength > 0 && !DropingPointId) ||
      (boardingLength === 0 && !DropingPointId) ||
      (droppingLength === 0 && !BoardingPointId);

    const isValidSelection =
      (boardingLength > 0 && BoardingPointId) ||
      (droppingLength > 0 && DropingPointId) ||
      (boardingLength === 0 && DropingPointId) ||
      (droppingLength === 0 && BoardingPointId) ||
      (boardingLength === 0 && droppingLength === 0);

    if (isInvalidSelection) return;

    if (isValidSelection) {
      naviagte("/bus-book", {
        state: { selectedSeat, detail, overAllPrice, selectedLocation, points },
      });
    } else {
      setcurrentTab(2);
    }
  };

  return (
    <>
      <Drawer
        width={650}
        closeIcon={null}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="bg-blue-50 text-black p-4">
          <p className="text-lg font-semibold">{data[0]?.travelName ?? ""}</p>
          <p className="flex mt-2 justify-start items-center gap-x-3">
            Sumerpur <FaArrowRight /> Jaipur
          </p>
          <p className="mt-2">
            {new Date(data[0]?.departureTime).toLocaleTimeString()} |{" "}
            {data[0]?.busType ?? ""}
          </p>
        </div>
        <div className="flex justify-start items-center font-semibold mt-2  flex-wrap gap-x-4">
          {tabs.map((i, index) => {
            return (
              <p
                key={index}
                onClick={() => setcurrentTab(i.id)}
                className={` ${
                  currentTab === i.id ? "border-primary border-b-2" : ""
                } py-2 cursor-pointer`}
              >
                {i.title}
              </p>
            );
          })}
        </div>
        {tabs[currentTab - 1].component}
        <div className="bg-white fixed bottom-0 w-[600px] h-32 shadow-[0_-2px_10px_6px_#e5e5e5] ">
          <div className="flex flex-col justify-center items-end">
            <div className="flex justify-between items-center w-full px-4 mt-2">
              <div>
                <p className="font-bold text-lg">Seat Selected</p>
                <p className="font-semibold">
                  {selectedSeat?.map(
                    (i, index) =>
                      `${i.seatName}${
                        index !== selectedSeat.length - 1 ? "," : ""
                      }`
                  )}
                </p>
              </div>
              <div>
                <p className=" text-lg font-bold">₹{overAllPrice.toFixed(0)}</p>
              </div>
            </div>
            <button
              onClick={handleNext}
              className="bg-primary text-white  p-2  mt-3 rounded-sm w-3/4 m-auto"
            >
              Next
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SelectSeatDrawer;
