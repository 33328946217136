import { Get, getAPIUrl, JSONPOST, Post } from "../../../../ApiServices/apiMethod";
import { busEndpoint } from "./BusEndpoint";

// travel functions
export const getBusCityLists = (param = "") => {
  const url = getAPIUrl(busEndpoint.cityList, param);
  return Get(url);
};

export const busSearch = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.busSearch, param);
  return Post(url, data);
};
export const busDetail = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.busDetail, param);
  return Post(url, data);
};
export const busLayout = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.busSeatLayout, param);
  return Post(url, data);
};
export const busBlock = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.busBlock, param);
  return JSONPOST(url, data);
};
export const busBook = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.busBook, param);
  return JSONPOST(url, data);
};
export const busBookDetail = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.getBookingDetail, param);
  return Post(url, data);
};

export const changeReq = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.changeReq, param);
  return Post(url, data);
};


export const getbusReport = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.busReport, param);
  return Post(url, data);
};

export const BusTicketDownload = (data, param = "") => {
  const url = getAPIUrl(busEndpoint.busTicketDownload, param);
  return Post(url, data);
};