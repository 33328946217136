export const busEndpoint={
    cityList:"Api/V1/TravelBus/busList",
    busSearch:"Api/V1/TravelBus/BusSearch",
    busSeatLayout:"Api/V1/TravelBus/busSeatLayout",
    busDetail:"Api/V1/TravelBus/busBoardingPointDetails",
    busBlock:"Api/V1/TravelBus/busBlock",
    busBook:"Api/V1/TravelBus/busBook",
    getBookingDetail:"Api/V1/TravelBus/getBookingDetails",
    changeReq:"Api/V1/TravelBus/sendChangeRequest",
    busReport:"Api/V1/TravelBus/busReport",
    busTicketDownload:"auth/hotelTicketPrint?booking_id="
}