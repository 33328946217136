import { Collapse, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { Hotelinfo } from "./Hotelfun";
import { Images } from "../../../../Controller";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";

const HotelDetaiDrawer = ({
  hotelCode,
  trace_id,
  resultIndex,
  isOpen,
  setIsOpen,
}) => {
  const [details, setDetails] = useState(null);
  const [initialPhoto, setInitialPhoto] = useState("");
  const [accordionItems, setAccordItems] = useState([]);
  const getHotelInfo = () => {
    const payload = {
      hotel_code: hotelCode,
      trace_id: trace_id,
      result_index: resultIndex,
    };
    Hotelinfo(payload)
      .then((res) => {
        if (res.status) {
          setDetails(res?.data);
          setAccordItems([
            {
              key: "1",
              label: (
                <p className="text-white font-semibold">Property Detail</p>
              ),
              children: res?.data?.hotelDetails?.description ? (
                <div
                  className="text-white"
                  dangerouslySetInnerHTML={{
                    __html: res?.data?.hotelDetails?.description,
                  }}
                ></div>
              ) : (
                <p className="text-white">No Description Found</p>
              ),
            },
            {
              key: "2",
              label: <p className="text-white font-semibold">Policy</p>,
              children: (
                <p className="text-white">
                  {res?.data?.hotelDetails?.hotelPolicy}
                </p>
              ),
            },
            {
              key: "3",
              label: <p className="text-white font-semibold">Attractions</p>,
              children: (
                <div className="flex justify-start items-center flex-wrap gap-2">
                  {res?.data?.hotelDetails?.attractions?.map((i, index) => {
                    return <p className="text-white">{i?.value},</p>;
                  })}
                </div>
              ),
            },
          ]);
          setInitialPhoto(res?.data?.hotelDetails?.images[0]);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getHotelInfo();
  }, []);
  // Custom expand icon function
  const customExpandIcon = (panelProps) => {
    return panelProps.isActive ? (
      <FaChevronDown color="white" className="text-primary" />
    ) : (
      <FaChevronRight color="white" className="text-primary" />
    );
  };
  return (
    <>
      <Drawer
        width={500}
        open={isOpen}
        onClose={() =>
          setIsOpen({
            hotelid: null,
            open: false,
            resultIndex: null,
          })
        }
        title={<p className="text-white">Hotel Details</p>}
        headerStyle={{ background: "var(--primary)", color: "white" }}
      >
        {details ? (
          <>
            <div className="">
              <img
                src={initialPhoto}
                alt="hotelImage"
                loading="lazy"
                placeholder={Images.NoHotel}
                className="rounded-lg bg-gray-50 shadow-sm shadow-gray-300 p-2   aspect-[3/1.5] object-contain w-full"
              />
            </div>
            <div className="flex justify-center items-center gap-x-2 mt-2 mx-4 overflow-x-scroll">
              {details?.hotelDetails?.images.map((i, index) => {
                return (
                  <img
                    onClick={() => setInitialPhoto(i)}
                    src={i}
                    alt="hotelImage"
                    loading="lazy"
                    className="rounded-lg  cursor-pointer   w-10 h-10"
                  />
                );
              })}
            </div>
            <div className="mt-2">
              <p className="text-xl font-bold">
                {details?.hotelDetails?.hotelName}
              </p>
              <p className="text-sm mt-2 font-semibold">
                {details?.hotelDetails?.address},{" "}
                {details?.hotelDetails?.pinCode}
              </p>
              <Collapse
                className="mt-4  bg-gray-400 rounded-lg"
                items={accordionItems}
                accordion
                bordered={false}
                expandIcon={customExpandIcon}
                defaultActiveKey={["1"]}
                expandIconPosition="right"
              />

              <p className="font-bold text-lg mt-2 underline underline-offset-4">
                Facilities
              </p>
              <div className="grid grid-cols-3 h-[350px] shadow-md shadow-gray-500 p-2 rounded-md overflow-scroll place-items-center gap-3 mt-2">
                {details?.hotelDetails?.hotelFacilities?.map((i, index) => {
                  return (
                    <p className=" text-xs px-2 rounded-md w-full flex justify-center items-center text-center h-full py-1 text-white bg-primary ">
                      {i}
                    </p>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <div className="mt-4">
            <div class="h-32 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-12 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-12 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-12 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default HotelDetaiDrawer;
