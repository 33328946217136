import { Button, DatePicker, Form, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { RiHotelBedFill } from "react-icons/ri";
import { GrFormAdd, GrFormSubtract } from "react-icons/gr";
import Tabbar from "../Tabbar";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { countryList, destinationList, HotelSearch } from "./Hotelfun";
import {
  createDynamicArray,
  dispatchtoast,
  filterOption,
} from "../../../../Utils";
import dayjs from "dayjs";
import { Images } from "../../../../Controller";
import HotelDetaiDrawer from "./HotelDetaiDrawer";
import { FaCircleInfo } from "react-icons/fa6";
import RoomsDrawer from "./RoomsDrawer";
import { MdOutlineBedroomParent } from "react-icons/md";
import { current } from "@reduxjs/toolkit";

const Hotel = () => {
  const [rooms, setRooms] = useState(1);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [childAge, setChildAge] = useState([]);
  const [RoomData, setRoomData] = useState([
    {
      noAdult: 1,
      noChild: 0,
      childAge: [],
    },
  ]);
  console.log({ RoomData });
  const [countryListData, setCountryList] = useState([]);
  const [countryLoading, setCountryLoading] = useState(false);
  const [destinationLoading, setDestinationLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [destinationListData, setDestinationList] = useState([]);
  const [searchParam, setSearchParams] = useSearchParams();
  const [openHotelDetailDrawer, setOpenHotelDetailDrawer] = useState({
    hotelid: null,
    open: false,
    resultIndex: null,
  });
  const [openRoomDetailDrawer, setOpenRoomDetailDrawer] = useState({
    hotelid: null,
    open: false,
    resultIndex: null,
    hotelName: null,
  });
  const [hotelList, setHotelList] = useState([]);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const navigate = useNavigate();
  const handleRooms = (type) => {
    if (type === "subtract") {
      if(rooms>1){
      setRooms(rooms - 1);
      let data = [...RoomData];
      data = data.slice(1, RoomData.length);
      setRoomData(data);
      }
    } else {
      setRooms(rooms + 1);
      setRoomData([
        ...RoomData,
        {
          noAdult: 1,
          noChild: 0,
          childAge: [],
        },
      ]);
    }
  };
  const handleAdult = (type, index) => {
    if (type === "Add") {
      let temp = [...RoomData];
      temp[index] = { ...temp[index], noAdult: temp[index].noAdult + 1 };
      setRoomData(temp);
    } else {
      let temp = [...RoomData];
      if (temp[index].noAdult > 1) {
        temp[index] = { ...temp[index], noAdult: temp[index].noAdult - 1 };
        setRoomData(temp);
      }
    }
  };
  const handleChild = (type, index) => {
    if (type === "Add") {
      let temp = [...RoomData];
      temp[index] = {
        ...temp[index],
        noChild: temp[index].noChild + 1,
        childAge: [...temp[index].childAge, { age: 1 }],
      };
      setRoomData(temp);
    } else {
      let temp = [...RoomData];
      if (temp[index].noChild > 0) {
        temp[index].childAge = temp[index].childAge.slice(
          1,
          temp[index].childAge.length
        );
        temp[index] = { ...temp[index], noChild: temp[index].noChild - 1 };
        setRoomData(temp);
      }
    }
  };
  const handleChildAge = (action, roomIndex, childIndex) => {
    setRoomData((prevData) => {
      return prevData.map((room, index) => {
        if (index === roomIndex) {
          // Clone the childAge array for modification
          let updatedChildAge = [...room.childAge];

          if (action === "add" && updatedChildAge[childIndex].age < 12) {
            // Increase the age, but limit to 12
            updatedChildAge[childIndex].age += 1;
          } else if (
            action === "subtract" &&
            updatedChildAge[childIndex].age > 1
          ) {
            // Decrease the age, but not below 1
            updatedChildAge[childIndex].age -= 1;
          }

          // Return updated room data
          return {
            ...room,
            childAge: updatedChildAge,
          };
        }
        return room;
      });
    });
  };

  useEffect(() => {
    // if (!memberServices.includes("12")) {
    //   navigate("/not-Available");
    // }
  }, []);
  const getCountryList = () => {
    setCountryLoading(true);
    countryList()
      .then((res) => {
        if (res.status) {
          setCountryList(
            res.data.countries.map((i) => {
              return {
                label: i.Name,
                value: i.Code,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setCountryLoading(false));
  };
  const getDestinationList = (code) => {
    setDestinationLoading(true);
    const payload = {
      country_code: "IN",
      search_type: 1,
    };
    destinationList(payload)
      .then((res) => {
        if (res.status) {
          setDestinationList(
            res.data.destinations.map((i) => {
              return {
                label: i.cityName,
                value: i.destinationId,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setDestinationLoading(false));
  };
  const handleSearch = (val) => {
    setPageLoader(true);
      const payload = {
        CheckInDate: dayjs(val.CheckInDate).format("DD/MM/YYYY"),
        NoOfNights: "1",
        CountryCode: val.CountryCode,
        CityId: val.CityId,
        ResultCount: 50,
        PreferredCurrency: "INR",
        GuestNationality: "IN",
        NoOfRooms: rooms,

        RoomGuests: RoomData.map((i) => {
          return {
            NoOfAdults: i.noAdult,
            NoOfChild: i.noChild,
            ChildAge: i.childAge.map((i) => i.age),
          };
        }),
        MaxRating: 5,
        MinRating: 4,
        ReviewScore: 4.5,
        IsNearBySearchAllowed: false,
      };
      HotelSearch(payload)
        .then((res) => {
          if (res.status && res.data.error?.errorCode === 0) {
            setHotelList(res.data);
            handleSetParam(
              "Other",
              JSON.stringify({ rooms, adults, children })
            );
          } else {
            dispatchtoast(res.data.error.errorMessage, true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setPageLoader(false));
    
  };
  const handleSetParam = (type, val) => {
    const param = new URLSearchParams(searchParam.toString());
    param.set(type, val);
    setSearchParams(param);
  };
  useEffect(() => {
    getCountryList();
  }, []);
  return (
    <>
      <Tabbar from={"hotel"} />
      <div className=" p-4 bg-white rounded-xl">
        <div className="flex justify-start items-center gap-3">
          <RiHotelBedFill className="text-primary" size={20} />
          <p className="font-semibold text-xl">Hotel Booking</p>
        </div>
        <Form onFinish={(val) => handleSearch(val)}>
          <div className="grid grid-cols-1 md:grid-cols-4 place-items-center gap-6 mt-4">
            <Form.Item
              name={"CountryCode"}
              rules={[
                {
                  required: true,
                  message: "Select Country",
                },
              ]}
              className="w-full"
            >
              <Select
                loading={countryLoading}
                showSearch
                onChange={(val) => {
                  getDestinationList(val);
                  handleSetParam("country", val);
                }}
                placeholder="Select Country"
                className="w-full mt-2"
                options={countryListData}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item
              name={"CityId"}
              rules={[
                {
                  required: true,
                  message: "Select City",
                },
              ]}
              className="w-full"
            >
              <Select
                loading={destinationLoading}
                showSearch
                onChange={(val) => handleSetParam("city", val)}
                placeholder="Select City"
                className="w-full mt-2"
                options={destinationListData}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item
              name={"CheckInDate"}
              rules={[
                {
                  required: true,
                  message: "Select Check In Date",
                },
              ]}
              className="w-full"
            >
              <DatePicker
                rootClassName="datepicker"
                disabledDate={(current) =>
                  current && current < dayjs().startOf("day")
                }
                popupClassName="bottom-calendar"
                className="w-full datepicker mt-2"
                onChange={(val) => handleSetParam("date", val)}
                placeholder="Check In"
              />
            </Form.Item>
            <div className="w-full">
              <div className="w-full grid grid-cols-3 gap-3 place-items-center border-2 border-primary col-span-1 rounded-full  p-2 mt-1">
                <p
                  onClick={() => handleRooms("subtract")}
                  className="bg-bswhite cursor-pointer flex justify-center items-center text-black w-6 h-6"
                >
                  <GrFormSubtract />
                </p>
                <div className=" flex justify-center gap-4 items-center w-full">
                  <p className="font-semibold">Rooms</p>
                  <p className="text-center bg-black w-3 h-3 md:w-6 md:h-6  flex justify-center items-center rounded-full text-white ">
                    {rooms}
                  </p>
                </div>
                <p
                  onClick={() => handleRooms("Add")}
                  className="bg-bswhite cursor-pointer flex justify-center items-center text-black w-6 h-6"
                >
                  <GrFormAdd />
                </p>
              </div>
            </div>
            {/* <Form.Item
              name={"CheckOutDate"}
              rules={[
                {
                  required: true,
                  message: "Select Check Out Date",
                },
              ]}
              className="w-full cursor-pointer"
            >
              <DatePicker
                rootClassName="datepicker"
                popupClassName="bottom-calendar"
                className="w-full datepicker mt-2"
                onChange={() => {}}
                placeholder="Check Out"
              />
            </Form.Item> */}
          </div>
          <div className=" border max-h-[400px] overflow-scroll border-gray-400 rounded-lg p-2  mt-4">
            {RoomData?.map((i, index) => {
              return (
                <>
                  <p className="font-bold">Room {index + 1} Guests</p>
                  <div className="grid grid-cols-1 bo rounded-lg p-2 md:grid-cols-2 lg:grid-cols-6 place-items-center gap-4 ">
                    <div className="w-full">
                      <p className="font-semibold">Adults</p>
                      <div className="w-full grid grid-cols-3 gap-3 place-items-center border-2 border-primary col-span-1 rounded-full  p-2 mt-1">
                        <p
                          onClick={() => handleAdult("subtract", index)}
                          className="bg-bswhite cursor-pointer flex justify-center items-center text-black w-6 h-6"
                        >
                          <GrFormSubtract />
                        </p>
                        <div className=" flex justify-center gap-4 items-center w-full">
                          <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                            {i.noAdult}
                          </p>
                        </div>
                        <p
                          onClick={() => handleAdult("Add", index)}
                          className="bg-bswhite cursor-pointer flex justify-center items-center text-black w-6 h-6"
                        >
                          <GrFormAdd />
                        </p>
                      </div>
                    </div>

                    <div className="w-full">
                      <p className="font-semibold">Childrens</p>
                      <div className="w-full grid grid-cols-3 gap-3 place-items-center border-2 border-primary col-span-1 rounded-full  p-2 mt-1">
                        <p
                          onClick={() => handleChild("subtract", index)}
                          className="bg-bswhite cursor-pointer flex justify-center items-center text-black w-6 h-6"
                        >
                          <GrFormSubtract />
                        </p>
                        <div className=" flex justify-center gap-4 items-center w-full">
                          <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                            {i.noChild}
                          </p>
                        </div>
                        <p
                          onClick={() => handleChild("Add", index)}
                          className="bg-bswhite cursor-pointer flex justify-center items-center text-black w-6 h-6"
                        >
                          <GrFormAdd />
                        </p>
                      </div>
                    </div>
                    {i.childAge.map((i, index1) => {
                      return (
                        <div className="w-full">
                          <p className="font-semibold">Child {index1 + 1} Age</p>
                          <div className="w-full grid grid-cols-3 gap-3 place-items-center border-2 border-primary col-span-1 rounded-full  p-2 mt-1">
                            <p
                              onClick={() =>
                                handleChildAge("subtract", index, index1)
                              }
                              className="bg-bswhite cursor-pointer flex justify-center items-center text-black w-6 h-6"
                            >
                              <GrFormSubtract />
                            </p>
                            <div className=" flex justify-center gap-4 items-center w-full">
                              <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                                {i.age}
                              </p>
                            </div>
                            <p
                              onClick={() =>
                                handleChildAge("add", index, index1)
                              }
                              className="bg-bswhite cursor-pointer flex justify-center items-center text-black w-6 h-6"
                            >
                              <GrFormAdd />
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {index !== RoomData.length - 1 && <hr />}
                </>
              );
            })}
          
          </div>
          <div className="col-span-1 w-full">
              <Button
                htmlType="submit"
                className="w-full border-none outline-none h-10 bg-primary rounded-none hover:bg-primaryhover font-bold text-white mt-4"
              >
                Search Hotel
              </Button>
            </div>
        </Form>
      </div>
      {pageLoader ? (
        <div className="mt-4">
          <div class="h-28 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
          <div class="h-28 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
          <div class="h-28 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
          <div class="h-28 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
        </div>
      ) : hotelList?.hotelResults?.length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 place-items-center gap-3">
          {hotelList?.hotelResults.map((i, index) => {
            return (
              <div
                key={index}
                className="p-3 w-full h-full bg-white relative shadow-sm shadow-gray-400 rounded-lg  mt-4"
              >
                <div className="grid relative grid-cols-1 md:grid-cols-12 gap-5">
                  <div className="col-span-3 border p-2 rounded-lg w-full">
                    <img
                      src={i.hotelPicture}
                      onError={(err) => {
                        err.target.src = Images.NoHotel;
                        err.target.alt = "courseImage";
                      }}
                      alt="purchased"
                      className="rounded-lg w-full  "
                    />
                  </div>
                  <div className="font-inter col-span-7 w-full">
                    <p className="text-sm xl:text-lg font-bold mt-2 ">
                      {i?.hotelName}
                    </p>
                    <p className="text-xs xl:text-sm font-semibold mt-2 ">
                      {i?.hotelAddress}
                    </p>
                  </div>
                  <div className="col-span-2 w-full text-start md:text-end">
                    <p className="font-bold text-xs xl:text-sm  ">
                      ₹ {i?.price?.publishedPrice}
                    </p>
                  </div>
                </div>
                <div className=" mt-2 flex justify-end items-center gap-2 right-2">
                  <p
                    onClick={() => {
                      setOpenRoomDetailDrawer({
                        open: true,
                        resultIndex: i.resultIndex,
                        hotelid: i.hotelCode,
                        hotelName: i.hotelName,
                      });
                    }}
                    className="bg-primary text-white flex justify-center items-center gap-x-2 px-4 py-1 rounded-md cursor-pointer"
                  >
                    <MdOutlineBedroomParent /> Rooms
                  </p>
                  <p
                    onClick={() => {
                      setOpenHotelDetailDrawer({
                        open: true,
                        resultIndex: i.resultIndex,
                        hotelid: i.hotelCode,
                      });
                    }}
                    className="bg-primary text-white flex justify-center items-center gap-x-2 px-4 py-1 rounded-md cursor-pointer"
                  >
                    <FaCircleInfo /> Info
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center mt-20">
          <img src={Images.manfind} className="w-[10%]" alt="emptycart" />
          <p className="font-bold text-lg font-inter mt-4">No Hotel Found</p>
        </div>
      )}
      {openHotelDetailDrawer.open && (
        <HotelDetaiDrawer
          setIsOpen={setOpenHotelDetailDrawer}
          isOpen={openHotelDetailDrawer.open}
          hotelCode={openHotelDetailDrawer.hotelid}
          resultIndex={openHotelDetailDrawer.resultIndex}
          trace_id={hotelList?.traceId}
        />
      )}
      {openRoomDetailDrawer.open && (
        <RoomsDrawer
          setIsOpen={setOpenRoomDetailDrawer}
          isOpen={openRoomDetailDrawer.open}
          hotelCode={openRoomDetailDrawer.hotelid}
          hotelName={openRoomDetailDrawer.hotelName}
          resultIndex={openRoomDetailDrawer.resultIndex}
          trace_id={hotelList?.traceId}
          roomguestArr={RoomData}
        />
      )}
    </>
  );
};

export default Hotel;
