import React, { useEffect, useState } from 'react'
import { busLayout } from './BusFun';
import { Images } from '../../../../Controller';
import { TbSteeringWheel } from 'react-icons/tb';
import { createDynamicArray } from '../../../../Utils';

const SeatLayout = ({resultIndex,traceId,selectedSeat,setSelectedSeat,overAllPrice,setOverAllPrice}) => {
    const [upperSeats, setUpperSeats] = useState([]);
    const [lowerSeats, setLowerSeats] = useState([]);
    const [lowerMaxRow, SetLowerMaxRow] = useState(null);
    const [upperMaxRow, setUpperMaxRow] = useState(null);
    const [pageLoader, setPageLoader] = useState(false);
    console.log({ upperSeats }, { lowerSeats }, { lowerMaxRow }, { upperMaxRow });
    const getBusLayout = () => {
      setPageLoader(true);
      const formdata = new FormData();
      formdata.append("result_index", resultIndex);
      formdata.append("trace_id", traceId);
      busLayout(formdata)
        .then((res) => {
          if (res.status) {
            const flattenData =
              res?.data?.seatLayoutDetails?.seatLayout?.seatDetails.flat();
            setUpperSeats(flattenData?.filter((i) => i.isUpper));
            SetLowerMaxRow(
              flattenData
                ?.filter((i) => !i.isUpper)
                .reduce((a, b) => {
                  a = 0;
                  if (Number(b.rowNo) > a) {
                    a = b.rowNo;
                  }
                  return Number(a);
                }, 0)
            );
            setUpperMaxRow(
              flattenData
                ?.filter((i) => i.isUpper)
                .reduce((a, b) => {
                  a = 0;
                  if (Number(b.rowNo) > a) {
                    a = b.rowNo;
                  }
                  return Number(a);
                }, 0)
            );
            setLowerSeats(flattenData?.filter((i) => !i.isUpper));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setPageLoader(false));
    };
    useEffect(() => {
      getBusLayout();
    }, []);
    const handleSelectSeat = (data) => {
        let temp = [...selectedSeat];
        // if (selectedSeat?.length < 5) {
        if (temp.find((i) => i.seatName === data?.seatName)) {
          temp = temp.filter((i) => i.seatName !== data?.seatName);
        } else {
          temp = [...temp, data];
        }
    
        setOverAllPrice(
          temp.reduce((a, b) => {
            a = a + b.seatFare;
            return a;
          }, 0)
        );
        setSelectedSeat(temp);
        // } else {
        //   dispatchtoast("Maximum 5 seats at a time allowed only", true);
        // }
      };
  return (
   <>
     <div className="flex justify-center bg-blue-50 rounded-md p-3 mt-4 items-center gap-x-3">
          <div className="flex justify-start items-center gap-x-2">
            <div className="w-6 h-6 bg-white border border-blue-200"></div>
            <p className="text-xs text-gray-500">Available fo all</p>
          </div>
          <div className="flex justify-start items-center gap-x-2">
            <div className="w-6 h-6 flex justify-center items-center bg-white border border-blue-200">
              <img src={Images.ladiessvg} />
            </div>
            <p className="text-xs text-gray-500">Available fo female</p>
          </div>{" "}
          <div className="flex justify-start items-center gap-x-2">
            <div className="w-6 h-6 flex justify-center items-center bg-white border border-blue-200">
              {" "}
              <img src={Images.mensvg} />
            </div>
            <p className="text-xs text-gray-500">Available fo male</p>
          </div>
        </div>
        {pageLoader ? (
          <div className="grid grid-cols-2 pb-28 place-items-center mt-4 gap-x-4">
            <div class="h-[400px] mt-4 animate-pulse bg-gray-400 rounded-md dark:bg-gray-400 w-full "></div>{" "}
            <div class="h-[400px] mt-4 animate-pulse bg-gray-400 rounded-md dark:bg-gray-400 w-full "></div>
          </div>
        ) : (
          <div className="grid grid-cols-2 pb-28 place-items-center mt-4 gap-x-4">
            <div className="w-full">
              <p className="flex justify-end items-center mb-4 font-bold">
                Lower cabin
              </p>
              <div className="border w-full h-full border-gray-300 rounded-lg px-4">
                <p className="flex mt-4 justify-end items-center text-gray-600 font-semibold gap-x-1">
                  Driver Cabin <TbSteeringWheel />
                </p>
                <div
                  style={{
                    gridTemplateColumns: `repeat(${
                      lowerMaxRow + 1
                    }, minmax(0, 1fr))`,
                  }}
                  className="grid  place-items-end my-4 gap-2"
                >
                  {createDynamicArray(lowerMaxRow).map((item, index) => {
                    return (
                      <div className="w-full">
                        {lowerSeats
                          ?.sort((a, b) => {
                            if (Number(a?.rowNo) > Number(b?.rowNo)) {
                              return -1;
                            }
                            return 1;
                          })
                          .map((item2) => {
                            return (
                              Number(item2?.rowNo) === item && (
                                <div
                                  onClick={() =>
                                    item2?.seatStatus && handleSelectSeat(item2)
                                  }
                                  style={{
                                    gridRow:
                                      item2.height == 2
                                        ? "span 2 / span 2"
                                        : "span 1 / span 1",
                                  }}
                                  className={` ${
                                    selectedSeat?.find(
                                      (i) => i.seatName === item2?.seatName
                                    )
                                      ? "border border-primary"
                                      : "border border-gray-300"
                                  } ${
                                    !item2?.seatStatus
                                      ? "bg-gray-200 cursor-not-allowed"
                                      : "cursor-pointer"
                                  }   ${
                                    item2.height == 2 ? "h-20 mt-5" : "h-10"
                                  } flex flex-col gap-y-1 justify-center ${
                                    item2.rowNo
                                  } items-center w-11 mb-2  rounded-lg`}
                                >
                                  {item2?.seatStatus && (
                                    <p className="text-[10px]">
                                      ₹{item2?.seatFare.toFixed(0)}
                                    </p>
                                  )}
                                  {item2?.isLadiesSeat && (
                                    <img src={Images.ladiessvg} alt="ladies" />
                                  )}
                                  {/* {item2?.isMalesSeat && (
                                <img src={Images.mensvg} alt="ladies" />
                              )} */}
                                </div>
                              )
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {upperSeats?.length > 0 && (
              <div className="w-full">
                <p className="flex justify-end items-center mb-4 font-bold">
                  Upper cabin
                </p>
                <div className="border w-full h-full border-gray-300 rounded-lg px-2">
                  <p className="flex mt-4 justify-end items-center text-gray-600 font-semibold gap-x-1">
                    Driver Cabin <TbSteeringWheel />
                  </p>
                  <div
                    style={{
                      gridTemplateColumns: `repeat(${upperMaxRow}, minmax(0, 1fr))`,
                    }}
                    className="grid  place-items-end my-4 gap-2"
                  >
                    {createDynamicArray(upperMaxRow).map((item, index) => {
                      return (
                        <div className="">
                          {upperSeats
                            ?.sort((a, b) => {
                              if (Number(a?.rowNo) > Number(b?.rowNo)) {
                                return -1;
                              }
                              return 1;
                            })
                            .map((item2) => {
                              return (
                                Number(item2?.rowNo) === item && (
                                  <div
                                    onClick={() =>
                                      item2?.seatStatus &&
                                      handleSelectSeat(item2)
                                    }
                                    style={{
                                      gridRow:
                                        item2.height == 2
                                          ? "span 2 / span 2"
                                          : "span 1 / span 1",
                                    }}
                                    className={` ${
                                      selectedSeat?.find(
                                        (i) => i.seatName === item2?.seatName
                                      )
                                        ? "border border-primary"
                                        : "border border-gray-300"
                                    } ${
                                      !item2?.seatStatus
                                        ? "bg-gray-200 cursor-not-allowed"
                                        : "cursor-pointer"
                                    }   ${
                                      item2.height == 2 ? "h-20 mt-5" : "h-10"
                                    } flex flex-col gap-y-1 justify-center ${
                                      item2.rowNo
                                    } items-center w-11 mb-2  rounded-lg`}
                                  >
                                    {item2?.seatStatus && (
                                      <p className="text-[10px]">
                                        ₹{item2?.seatFare.toFixed(0)}
                                      </p>
                                    )}
                                    {item2?.isLadiesSeat && (
                                      <img
                                        src={Images.ladiessvg}
                                        alt="ladies"
                                      />
                                    )}
                                    {/* {item2?.isMalesSeat && (
                                <img src={Images.mensvg} alt="ladies" />
                              )} */}
                                  </div>
                                )
                              );
                            })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
   </>
  )
}

export default SeatLayout