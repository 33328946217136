import { Button, Card, Checkbox, Form, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  convertKeysToPascalCase,
  createDynamicArray,
  dispatchtoast,
  emailPattern,
  mobilePattern,
} from "../../../../Utils";
import CommonInput from "../../../../Common/CommonInput";
import { useLocation, useNavigate } from "react-router-dom";
import { busBlock, busBook } from "./BusFun";

const BusBook = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  const { detail, overAllPrice, selectedSeat, selectedLocation, points } =
    location?.state || {};
  const [FormSpinner, setFormSpinner] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    let data = [];

    const passengers = selectedSeat?.map((i) => {
      return {
        PaxType: i?.seatName,
      };
    });
    data = [...data, ...passengers];
    form.setFieldsValue({ fields: data });
  }, []);
  const handleBook = (val) => {
    setFormSpinner(true);
    let payload = {
      TravelName: detail.data[0].travelName,
      Origin: points?.Origin,
      Destination: points?.Destination,
      ResultIndex: detail?.resultIndex,
      TraceId: detail?.traceId,
      BoardingPointId: selectedLocation?.BoardingPointId ?? 0,
      DropingPointId: selectedLocation?.DropingPointId ?? 0,
      Passenger: val?.fields.map((i, index) => {
        return {
          LeadPassenger: i.IsLeadPax ?? true,
          PassengerId: 0,
          Address: val?.Address,
          Title: i.Title,
          Address: val.Address,
          IdNumber: val.IdNumber,
          IdType: val.IdType,
          Age: i.Age,
          Email: val["Email Id"],
          FirstName: i.FirstName,
          Gender: i.Gender,
          LastName: i.LastName,
          Phoneno: val.ContactNo,
          Seat: convertKeysToPascalCase(selectedSeat[index]),
        };
      }),
    };
    busBlock(payload)
      .then((res) => {
        if (res.status) {
          busBook(payload)
            .then((res) => {
              if (res.status) {
                navigate("/bus-bookingDetail", {
                  state: { bookingId: res.booking_id },
                });
                dispatchtoast(res.message);
              }
            })
            .catch((err) => console.log(err))
            .finally(() => setFormSpinner(false));
        } else {
          setFormSpinner(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const onCheckboxChange = (index) => {
    const fieldsValue = form.getFieldValue("fields");
    const updatedFieldsValue = fieldsValue.map((field, i) => ({
      ...field,
      IsLeadPax: i === index,
    }));
    form.setFieldsValue({ fields: updatedFieldsValue });
  };
  return (
    <>
      <div className="grid grid-cols-7 place-items-start gap-x-3">
        <div className="col-span-7 md:col-span-4 w-full">
          <Spin spinning={FormSpinner}>
            <div className="bg-white max-h-[80vh] overflow-scroll rounded-lg p-3">
              <p className="font-bold">Enter Passanger Detail</p>
              <Form
                onFinish={(val) => {
                  handleBook(val);
                  console.log(val);
                }}
                form={form}
                onFinishFailed={(err) => {
                  console.log(err);
                }}
                name="dynamic_form_complex"
                autoComplete="off"
                initialValues={{
                  fields: createDynamicArray(selectedSeat?.length - 1),
                }}
              >
                <div className="grid grid-cols-1 sm:grid-cols-3  gap-x-4 mt-2">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter ContatcNo.",
                      },
                      {
                        pattern: mobilePattern,
                        message: "Enter valid mobile.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"ContactNo"}
                    label="Mobile"
                  >
                    <CommonInput placeholder={"Enter Mobile"} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter Email Id.",
                      },
                      {
                        pattern: emailPattern,
                        message: "Enter valid email Id.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"Email Id"}
                    label="Email"
                  >
                    <CommonInput placeholder={"Enter Email"} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter Address.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"Address"}
                    label="Address"
                  >
                    <CommonInput placeholder={"Enter Address"} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter Id Type.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"IdType"}
                    label="Id Type"
                  >
                    <Select
                      value={"Mr"}
                      options={[
                        {
                          label: "Pan no",
                          value: "Pan no",
                        },
                        {
                          label: "Voterid card",
                          value: "Voterid card",
                        },
                        {
                          label: "Passport",
                          value: "Passport",
                        },
                      ]}
                      placeholder="Select Id Type"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter Id Number.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"IdNumber"}
                    label="Id Number"
                  >
                    <CommonInput placeholder={"Enter Id Number"} />
                  </Form.Item>
                </div>
                <Form.List name="fields">
                  {(fields) => (
                    <div>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => {
                          return (
                            <>
                              <Card
                                size="small"
                                bordered={false}
                                className="mt-4"
                                title={`Passanger ${name + 1}`}
                                key={key}
                              >
                                <Form.Item
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     whitespace: true,
                                  //     message: "Select Passanger Type.",
                                  //   },
                                  // ]}
                                  className="w-full"
                                  labelCol={{ span: 24 }}
                                  name={[name, "IsLeadPax"]}
                                  fieldKey={[name, "IsLeadPax"]}
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    checked={form.getFieldValue([
                                      "fields",
                                      name,
                                      "IsLeadPax",
                                    ])}
                                    onChange={() => onCheckboxChange(index)}
                                  >
                                    Main Member
                                  </Checkbox>
                                </Form.Item>
                                <div className="grid grid-cols-1 sm:grid-cols-3  gap-x-4 mt-2">
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Select Seat NAme.",
                                      },
                                    ]}
                                    className="w-full"
                                    labelCol={{ span: 24 }}
                                    name={[name, "PaxType"]}
                                    fieldKey={[name, "PaxType"]}
                                    label="Seat Name"
                                  >
                                    <CommonInput
                                      disabled
                                      placeholder="Seat Name"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Select Title.",
                                      },
                                    ]}
                                    className="w-full"
                                    labelCol={{ span: 24 }}
                                    name={[name, "Title"]}
                                    fieldKey={[name, "Title"]}
                                    label="Title"
                                  >
                                    <Select
                                      value={"Mr"}
                                      options={[
                                        {
                                          label: "Mr",
                                          value: "Mr",
                                        },
                                        {
                                          label: "Ms",
                                          value: "Ms",
                                        },
                                        {
                                          label: "Mrs.",
                                          value: "Mrs",
                                        },
                                      ]}
                                      placeholder="Select Title"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Enter Age.",
                                      },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    name={[name, "FirstName"]}
                                    label="FirstName"
                                  >
                                    <CommonInput
                                      placeholder={"Enter First Name"}
                                    />
                                  </Form.Item>{" "}
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Enter LastName.",
                                      },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    name={[name, "LastName"]}
                                    label="LastName"
                                  >
                                    <CommonInput
                                      placeholder={"Enter Last Name"}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Enter Age.",
                                      },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    name={[name, "Age"]}
                                    label="Age"
                                  >
                                    <CommonInput placeholder={"Enter Age"} />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Select Gender.",
                                      },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    name={[name, "Gender"]}
                                    label="Gender"
                                  >
                                    <Select
                                      options={[
                                        {
                                          label: "Male",
                                          value: 1,
                                        },
                                        {
                                          label: "Female",
                                          value: 2,
                                        },
                                      ]}
                                      placeholder={"Select Gender"}
                                    />
                                  </Form.Item>
                                </div>
                              </Card>
                            </>
                          );
                        }
                      )}

                      {/* <Button type="dashed" onClick={() => add()} block>
                      + Add Item
                    </Button> */}
                    </div>
                  )}
                </Form.List>
                <Form.Item className="w-full">
                  <Button
                    loading={FormSpinner}
                    style={{ color: "white" }}
                    className="w-full mt-2 bg-primary text-white"
                    htmlType="submit"
                  >
                    Book
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Spin>
        </div>
        <div className="col-span-7 mt-2 md:mt-0 w-full md:col-span-3">
          <div className="bg-white  max-h-[80vh] overflow-scroll rounded-lg p-3">
            <p className="font-bold text-sm rounded-sm mt-2 bg-primary text-white px-4 py-2">
              Fare Summary
            </p>
            <div className="p-3">
              <p className="font-semibold text-gray-400">
                Passengers: {selectedSeat?.length}
              </p>
              <div className=" flex justify-between items-center">
                <p className="w-full text-sm text-gray-600 font-bold">
                  Base Fare
                </p>
                <p className="w-full text-lg text-black font-bold  text-end">
                  ₹ {overAllPrice}
                </p>
              </div>{" "}
              <hr className="mt-2" />
              <div className="mt-2 flex justify-between items-center">
                <p className="w-full text-lg text-primary font-bold">
                  Grand Total:
                </p>
                <p className="w-full text-primary font-bold  text-2xl text-end">
                  ₹ {overAllPrice}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white mt-4  max-h-[80vh] overflow-scroll rounded-lg p-3">
            <p className="font-bold text-sm rounded-sm mt-2 bg-primary text-white px-4 py-2">
              Trip Summary
            </p>
            {detail?.data?.map((i, index) => {
              return (
                <div className="border border-gray-300 p-1 rounded-md mt-2">
                  {/* <p className="flex justify-end items-center font-semibold pr-2 cursor-pointer">
                    Cancellation Policy
                  </p> */}
                  <div className="p-3">
                    <p className="font-bold">
                      {index === 0 ? points?.Origin : points?.Destination}
                    </p>
                    <p className="font-semibold mt-1">
                      {new Date(i?.departureTime).toLocaleString()}
                    </p>
                    <p className="mt-1 font-medium">
                      {selectedLocation?.boardingPoint ?? "--"}
                    </p>
                  </div>
                  <hr className="mt-2" />
                  <div className="p-3">
                    <p className="font-bold">
                      {index === 0 ? points?.Destination : points?.Origin}
                    </p>
                    <p className="font-semibold mt-1">
                      {new Date(i?.arrivalTime).toLocaleString()}
                    </p>
                    <p className="mt-1 font-medium">
                      {selectedLocation?.droppingPoint ?? "--"}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusBook;
