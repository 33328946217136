import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../../../Theme/theme";
import { Drawer } from "antd";
import { busDetail } from "./BusFun";
import { FaRegClock } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";

const BusDetailDrawer = ({ open, setOpen, detail }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const [tabs, setTabs] = useState([
    {
      index: 1,
      title: "Boarding Points",
      data: [],
    },
    {
      index: 2,
      title: "Dropping Points",
      data: [],
    },
    {
      index: 3,
      title: "Cancellation Policy",
      data: [],
    },
  ]);

  const getBusDetail = () => {
    busDetail({ result_index: detail.resultIndex, trace_id: detail.traceId })
      .then((res) => {
        if (res.status) {
          let temp = [...tabs];
          temp[0] = { ...temp[0], data: res?.data.boardingPointsDetails };
          temp[1] = { ...temp[1], data: res?.data.droppingPointsDetails };
          temp[2] = { ...temp[2], data: detail?.data[0]?.cancellationPolicies };
          setTabs(temp);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getBusDetail();
  }, []);
  return (
    <Drawer
      open={open}
      className="bg-red-400"
      title={<p className="text-white">Bus Details</p>}
      headerStyle={{
        background: ThemeColor.primary,
        color: ThemeColor.bswhite,
      }}
      width={800}
      onClose={() => setOpen(false)}
    >
      <div>
        <div className="grid grid-cols-3 place-items-center">
          {tabs.map((i, index) => {
            return (
              <div
                key={index}
                onClick={() => setCurrentTab(index)}
                className={`w-full flex justify-center items-center shadow-sm font-semibold cursor-pointer shadow-gray-200 p-2 ${
                  index === currentTab ? "bg-primary text-white" : ""
                } `}
              >
                <p>{i.title}</p>
              </div>
            );
          })}
        </div>
        {tabs[currentTab]?.data?.length <= 0 ? (
          <>
            <div class="h-8 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-8 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-8 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-8 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
          </>
        ) : ( currentTab===0|| currentTab===1?
          tabs[currentTab]?.data.map((i, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-2  place-items-start mt-4 gap-2 bg-white shadow-sm shadow-gray-500  p-2"
              >
                <p className="font-bold flex justify-start items-center gap-x-2">
                  <FaRegClock />{" "}
                  {new Date(i?.cityPointTime).toLocaleTimeString()}
                </p>
                <div className="font-semibold">
                  <p className="flex justify-start items-center gap-x-2">
                    <IoLocationOutline />
                    Location: {i?.cityPointLocation}
                  </p>
                  <p className="mt-2 flex justify-start items-center gap-x-2">
                    <IoLocationOutline />
                    Address: {i?.cityPointName}
                  </p>
                  {i.hasOwnProperty("cityPointLandmark") &&
                  <p className="mt-2 flex justify-start items-center gap-x-2">
                    <IoLocationOutline />
                    Landmark: {i?.cityPointLandmark}
                  </p>
          }
                  {i.hasOwnProperty("cityPointContactNumber") &&
                  <p className="mt-2 flex justify-start items-center gap-x-2">
                    <IoLocationOutline />
                    Contact: {i?.cityPointContactNumber}
                  </p>
          }
                </div>
              </div>
            );
          }):tabs[currentTab]?.data.map((i, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-1  place-items-start mt-4 gap-2 bg-white shadow-sm shadow-gray-500  p-2"
              >
              
                <div className="font-semibold">
                  <p className="flex justify-start items-center gap-x-2">
                    
                    Cancellation Charge: {i?.cancellationCharge}
                  </p>
                  <p className="mt-2 flex justify-start items-center gap-x-2">
                    
                    Policy: {i?.policyString}
                  </p>
                  <p className="mt-2 flex justify-start items-center gap-x-2">
                    
                    To Date: {new Date(i?.toDate).toDateString()}
                  </p>
               
                </div>
              </div>
            );
          })
        )}
        {/* <p className="font-semibold">Cancellation Policy:</p>
        <div className="grid grid-cols-3 place-items-start gap-2">
          {[1, 2, 3, 4, 5].map((i, index) => {
            return (
              <div className="bg-primary capitalize text-white font-semibold rounded-sm p-3">
                <p className="">cancellation Charge: 10</p>
                <p className="mt-2">cancellation Charge: 2024-08-14T10:22:56</p>
                <p className="mt-2">cancellation Charge: 2024-08-14T10:22:56</p>
                <p className="mt-2"> Till 11:35 on 14 Aug 2024</p>
              </div>
            );
          })}
        </div> */}
      </div>
    </Drawer>
  );
};

export default BusDetailDrawer;
