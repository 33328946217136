import {
    Button,
    DatePicker,
    Select,
    Skeleton,
    Spin,
    Table,
    Tag,
    Tooltip,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { dispatchtoast, formatDateAndTime, trimString } from "../../../Utils";
  import { getDmtReport } from "../../../ApiServices/Apifun";
  import dayjs from "dayjs";
  import ReportDrawer from "../../../Common/ReportDrawer";
  import { AiOutlineSearch } from "react-icons/ai";
  import Content from "../../../Common/ReceiptContent";
  import { setselectedTab } from "../../../Store/B2bslice";
  import { useDispatch } from "react-redux";
  import CommonInput from "../../../Common/CommonInput";
  import CommonPagination from "../../../Common/Pagination";
  import { getFlightReport } from "../OtherServices/Flights/FlightFun";
  import { FaEye } from "react-icons/fa";
  import { useNavigate } from "react-router-dom";
import { getbusReport } from "../OtherServices/Bus/BusFun";
  
  const BusReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState([]);
    const [current, setCurrent] = useState(1);
    const [searchKeyword, setSearchKeyWord] = useState("");
    const [searchcolumn, setSearchColumn] = useState(null);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [totalPages, setTotalPages] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
    const [result, setResult] = useState({ data: null, transaction_id: null });
    const [searchOptions, setSearchOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const ReportColumn = [
      {
        title: "Date",
        dataIndex: "Date",
        key: "Date",
        render: (text) => (
          <p className="font-medium w-[150px]">{formatDateAndTime(text)}</p>
        ),
      },
      {
        title: "Transaction Id",
        dataIndex: "transaction_id",
        key: "transaction_id",
        render: (text) => {
          return (
            <>
              <p className=" font-medium  overflow-y-scroll">{text}</p>
            </>
          );
        },
      },
      {
        title: "Amount",
        dataIndex: "total_fare",
        key: "total_fare",
        render: (text) => (
          <p style={{ width: "80px" }} className=" font-medium">
            {text} Rs.
          </p>
        ),
      },
      {
        title: "PNR",
        dataIndex: "pnr",
        key: "pnr",
        render: (text) => (
          <p className="font-medium   px-3 overflow-y-scroll">
            {text ? text : "-"}
          </p>
        ),
      },
      {
        title: "Booking Id",
        dataIndex: "booking_id",
        key: "booking_id",
        render: (text) => {
          return (
            <>
              <p className="font-medium w-[80px]  overflow-y-scroll">
                {text ? text : "-"}
              </p>
            </>
          );
        },
      },
      {
        title: "Origin",
        dataIndex: "origin",
        key: "origin",
        render: (text) => {
          return <p>{text}</p>;
        },
      },
      {
        title: "Destination",
        dataIndex: "destination",
        key: "destination",
        render: (text) => {
          return <p>{text}</p>;
        },
      },
      {
        title: "Passenger",
        dataIndex: "passenger_name",
        key: "passenger_name",
        render: (text) => {
          return <p className="w-[120px]">{text}</p>;
        },
      },
      {
        title: "Mobile No.",
        dataIndex: "contact_no",
        key: "contact_no",
        render: (text) => {
          return <p className="w-[100px]">{text}</p>;
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text) => {
          return <p>{text}</p>;
        },
      },
      {
        title: "Departure Time",
        dataIndex: "departure_time",
        key: "departure_time",
        render: (text) => {
          return (
            <>
              <p className="font-medium w-[120px] overflow-y-scroll">
                {text ? formatDateAndTime(text) : "-"}
              </p>
            </>
          );
        },
      },
      {
        title: "Arrival Time",
        dataIndex: "arrival_time",
        key: "arrival_time",
        render: (text) => {
          return (
            <>
              <p className="font-medium w-[120px] overflow-y-scroll">
                {text ? formatDateAndTime(text) : "-"}
              </p>
            </>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          return (
            <>
              {text === "1" ? (
                <Tag color="green">Complete</Tag>
              ) : text === "0" ? (
                <Tag color="red">Pending</Tag>
              ) : text === "2" ? (
                <Tag color="yellow">Failed</Tag>
              ) : text === "3" ? (
                <Tag color="yellow">Cancelled</Tag>
              ) : text == "4" ? (
                <Tag color="yellow">PNR Released</Tag>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        title: "Remarks",
        dataIndex: "remark",
        key: "remark",
        render: (text) => {
          return (
            <>
              <Tooltip title={text}>
                <p className="font-medium w-[150px]  px-3 overflow-y-scroll">
                  {text ? trimString(text) : "-"}
                </p>
              </Tooltip>
            </>
          );
        },
      },
      {
        title: "booking Detail",
        dataIndex: "",
        key: "",
        render: (text, record) => {
          return (
            <>
              <div
                onClick={() =>
                {
                  if(record?.booking_id){
                    navigate("/bus-bookingDetail", {
                      state: { bookingId: record?.booking_id},
                    })
                  }else{
                    dispatchtoast("Booking is in pending state",true)
                  }
                }
                 
                }
                className="bg-primary rounded-full flex justify-center items-center w-6 h-6"
              >
                <FaEye color="white" />
              </div>
            </>
          );
        },
      },
    ];
  
    const getReport = (
      search_keyword = searchKeyword,
      search_column = searchcolumn,
      from_date = fromDate,
      to_date = toDate
    ) => {
      setLoading(true);
      const payload = {
        per_page: pageSize,
        page_no: current,
        search_keyword: search_keyword,
        search_column: search_column,
        from_date: from_date,
        to_date: to_date,
      };
      const formdata = new FormData();
      Object.keys(payload).map((key) => formdata.append(key, payload[key]));
      getbusReport(formdata)
        .then((res) => {
          setDataSource(res.data);
          // setCurrent(Number(res.page_no));
          setTotalPages(res.total);
          setSearchOptions(
            res?.filter_column_name?.map((i, index) => {
              return {
                label: i,
                value: res.filter_column_key[index],
              };
            })
          );
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    };
    useEffect(() => {
      getReport();
    }, [current, pageSize]);
    useEffect(() => {
      dispatch(setselectedTab("11.100"));
    }, []);
    return (
      <>
        <div className="">
          <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
            <div className="flex justify-between items-center">
              <p className="font-bold text-[15px] mb-2">Bus Report</p>
              <p
                onClick={() => {
                  getReport("", "", "", "");
                  setSearchKeyWord("");
                  setSearchColumn(null);
                  setFromDate("");
                  setToDate("");
                }}
                className="font-medium text-xs cursor-pointer bg-bsred hover:bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2"
              >
                Clear Filter
              </p>
            </div>
            <div className="selectionFields grid grid-cols-1 md:grid-cols-3 gap-4 place-items-center ">
              <div className="w-full">
                <p className={`required text-textGray mt-2`}>
                  Select Search Type
                </p>
                <Select
                  placeholder="Select search type"
                  value={searchcolumn}
                  onChange={(val) => setSearchColumn(val)}
                  className="w-full mt-2"
                  options={searchOptions}
                />
              </div>
              <div className="w-full">
                <p className={`required text-textGray `}>Keyword</p>
                {searchcolumn === "bus_booking.created_at" ||
                searchcolumn === "bus_booking.departure_time" ||
                searchcolumn === "bus_booking.arrival_time" ? (
                  <DatePicker
                    // value={dayjs("2023-09-07","YYYY-MM-DD")}
                    popupClassName="bottom-calendar"
                    allowClear
                    // value={searchKeyword}
                    className="w-full datepicker mt-4"
                    onChange={(val) =>
                      setSearchKeyWord(dayjs(val).format("YYYY-MM-DD"))
                    }
                    placement="bottomLeft"
                    placeholder="Search"
                  />
                ) : (
                  <CommonInput
                    placeholder="Seacrh"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyWord(e.target.value)}
                    className="outline-none text-xs border mt-4 w-full  border-gray-200 focus:border-primary   px-2 "
                  />
                )}
              </div>
              <div className="w-full">
                <p className={`required text-textGray mt-2`}>
                  From Date - To Date
                </p>
                <DatePicker.RangePicker
                  allowClear
                  onChange={(val) => {
                    setFromDate(val ? dayjs(val[0]).format("YYYY-MM-DD") : "");
                    setToDate(val ? dayjs(val[1]).format("YYYY-MM-DD") : "");
                  }}
                  className="w-full mt-2"
                />
              </div>{" "}
            </div>
            <div className="w-full flex justify-end items-center mt-3">
              <Button
                // disabled=
                onClick={() => getReport()}
                style={{ color: "white" }}
                className=" font-semibold flex justify-between gap-2 items-center hover:bg-primaryhover bg-primary border-none"
              >
                <AiOutlineSearch />
                <p>Search</p>
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-4">
            <Spin spinning={loading}>
              <Table
                className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
                columns={ReportColumn}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    // onClick: (event) => {
                    //   setResult({
                    //     data: {
                    //       Date: record.Date,
                    //       Name: record.beneficiary_name,
                    //       Account: record.account_no,
                    //       IFSC: record.ifsc_code,
                    //       "Bank Name": record.bank_name,
                    //       "Transfer Mode": record.transfer_mode,
                    //       Amount: record.amount,
                    //       Surcharge: record.surcharge,
                    //       RRN: record.rrn,
                    //       Remark: record.remark,
                    //     },
                    //     transaction_id: record.transaction_id,
                    //   });
                    //   setOpenReceiptDrawer(true);
                    // },
                    className: "cursor-pointer text-[12px]",
                  };
                }}
                dataSource={dataSource}
              />
              <div className="mt-2 mb-8 flex justify-end items-center">
                <CommonPagination
                  totalPages={totalPages}
                  current={current}
                  pageSize={pageSize}
                  setCurrent={setCurrent}
                  setPageSize={setPageSize}
                />
              </div>
            </Spin>
          </div>
        </div>
        {/* {openReceiptDrawer && (
          <ReportDrawer
            title={"DMT Receipt"}
            open={openReceiptDrawer}
            setOpen={setOpenReceiptDrawer}
            content={<Content data={result} />}
          />
        )} */}
      </>
    );
  };
  
  export default BusReport;
  