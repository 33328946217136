import { Get, getAPIUrl, Post } from "../../../ApiServices/apiMethod";

const invoiceEndpoint = {
  invoiceList: "Api/V1/Member/invoiceListing",
  downloadInvoice: "Api/V1/Member/downloadInvoice",
};

export const invoiceList = (param = "") => {
  const url = getAPIUrl(invoiceEndpoint.invoiceList, param);
  return Get(url);
};
export const downloadInvoice = (data, param = "") => {
  const url = getAPIUrl(invoiceEndpoint.downloadInvoice, param);
  return Post(url, data);
};
