import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  DeviceInfo,
  MantraIrisdeviceInfoAvdm,
  MantraPIDOPTS,
  MantradeviceInfoAvdm,
  MorphpoPIDOPTS,
  StartekDeviceInfo,
  ToastContainerMsg,
  aadharPattern,
  captureUrl,
  convertXmlToJson,
  deviceOptions,
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  handleSubmitForAeps,
  mantrairisPIDOPTS,
  mobilePattern,
  parseXml,
  startekPIDOPTS,
} from "../../../../../Utils";
import {
  AepsBalanceEnquiry,
  AepsYesBalanceEnquiry,
  getAllAepsYesMasters,
} from "../../../../../ApiServices/Apifun";
import { xml2json } from "../../../../../Utils/xml2json";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReportDrawer from "../../../../../Common/ReportDrawer";
import { setselectedTab } from "../../../../../Store/B2bslice";
import CommonInput from "../../../../../Common/CommonInput";
import { Images } from "../../../../../Controller";

const YesBalanceEnquiry = ({ setOpenModal,setType }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [openReceiptDrawer, setOpemReceiptDrawer] = useState(false);
  const [userBanks, setUserBanks] = useState([]);
  const[consent,setConsent]=useState(false)
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleAepsMasters = () => {
      getAllAepsYesMasters()
        .then((res) => {
          setUserBanks(
            res.data.aeps_banks.map((i) => ({
              label: i.bank_name,
              value: i.id,
              key: i.id,
            }))
          );
        })
        .catch((err) => console.log(err));
    };
    handleAepsMasters();
  }, []);

  const capture = (val, port=11100) => {
    setBtnLoading(true);
    axios({
      method: "CAPTURE", // Set the custom request method here
      url:
        val.device === "morpho"
          ? `https://localhost:${port}/capture`
          : val.device === "startek"
          ? "https://localhost:11200/rd/capture"
          : `https://127.0.0.1:${port}/rd/capture`,
      data:
        val.device === "morpho"
          ? MorphpoPIDOPTS
          : val.device === "mantra"
          ? MantraPIDOPTS
          : val.device === "startek"
          ? startekPIDOPTS
          : mantrairisPIDOPTS,
      headers: {
        "Content-Type": "text/xml",
        Accept: "text/xml",
      },
    })
      .then((response) => {
        const parsed = parseXml(response.data);
        const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
        // const test1 = response.data;
        // const test2 = test1.search("errCode");
        // const test6 = getPosition(test1, '"', 2);
        // const test4 = test2 + 9;
        // const test5 = test1.slice(test4, test6);
        if (xmltojson.PidData.Resp.errCode !== "0") {
          dispatchtoast(
            `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
            true
          );
          setBtnLoading(false);
        } else {
          dispatchtoast("Fingerprint Captured SuccessFully");
          const payload = {
            consent:consent?"Y":"N",
            mac_address:"",
            device_id: navigator.userAgent??"",
            customer_mobile: val.customer_mobile,
            serial_number: Array.isArray(
              xmltojson.PidData.DeviceInfo.additional_info.Param
            )
              ? xmltojson.PidData.DeviceInfo.additional_info.Param[0].value
              : xmltojson.PidData.DeviceInfo.additional_info.Param.value,
            customer_aadhaar_number: val.customer_aadhaar_number,
            bio_metricdata: response.data.replace(/"/g, "'"),
            user_bank: val.user_bank.value,
            user_bank_name: val.user_bank.label,
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
          };
          const formdata = new FormData();
          Object.entries(payload).forEach(([key, val]) =>
            formdata.append(key, val)
          );
          AepsYesBalanceEnquiry(formdata)
            .then((res) => {
              if (res.status_1 === false) {
                setType("status1")
                setOpenModal(true);
              }
              if (res.data.status === "FAILED") {
                dispatchtoast(res.message, true);
              } else {
                setResponse(res.data);
                setOpemReceiptDrawer(true);
                dispatchtoast(res.data.message);
              }
            })
            .catch((err) => {
              if (err.response.data.status_1 === false) {
                setType("status1")
                setOpenModal(true);
              }
            })
            .finally(() => setBtnLoading(false));
          // .finally(() => navigate("/dashboard"));
        }
      })
      .catch((error) => {
        setBtnLoading(false);
      });
  };
  const Content = () => {
    return (
      <>
        <div className="border-2 rounded-md w-9/12 border-dashed border-gray-500  ml-4 mr-4 text-center mt-5">
          <p className="-mt-2 bg-white w-[100px] flex justify-center items-center font-semibold m-auto text-[11px]">
            Transaction I'd
          </p>
          <p className="text-[11px] mt-2 mb-2">{response.userTransactionId}</p>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Bank RRN</p>
            <p className="text-black">{response.bankRrn}</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Balance Amount</p>
            <p className="text-black">
              {parseFloat(response.balanceAmount / 100).toFixed(2)} Rs.
            </p>
          </div>
        </div>
        {/* <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Merchant Name</p>
            <p className="text-black">{response?.merchant_name??"-"}</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Bank Name</p>
            <p style={{ wordBreak: "break-word" }} className="text-black">
              {response.bank_name}
            </p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Aadhar Number</p>
            <p className="text-black">{response.masked_aadhaar}</p>
          </div>
        </div> */}
      </>
    );
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <div className="flex justify-between items-center">
          <p className="font-bold text-[15px] ">Balance Enquiry</p>
          <div className="flex justify-end gap-2 items-center">
           <img
              src={Images.WebsiteLogo}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
             <img
              src={Images.yesBank}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
           </div>
          </div>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) =>consent? handleSubmitForAeps(val, capture):dispatchtoast("Please select term and conditions 😊.",true)}
          >
            <Form.Item
              name={`customer_mobile`}
              label={"Mobile Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Mobile Number!",
                },
                {
                  pattern: mobilePattern,
                  message: "Invalid mobile number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Mobile Number"
                name="customer_mobile"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name={`customer_aadhaar_number`}
              label={"Aadhar Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter aadhar Number!",
                },
                {
                  pattern: aadharPattern,
                  message: "Invalid aadhar number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Aadhar Number"
                name="customer_aadhaar_number"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>{" "}
            <Form.Item
              name={`user_bank`}
              label={"Select Bank"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one bank!",
                },
              ]}
            >
              <Select
                showSearch
                labelInValue={true}
                placeholder="Select a bank"
                filterOption={filterOption}
                className="w-full mt-2"
                options={userBanks}
              />
            </Form.Item>
            <Form.Item
              name={`device`}
              label={"Select Device"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one device!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Device"
                filterOption={filterOption}
                className="w-full mt-2"
                options={deviceOptions}
              />
            </Form.Item>
            <Form.Item
            
              name={`consent`}
              labelCol={{ span: 24 }}
            >
              <div className="mt-2 flex justify-start items-center gap-x-3  checkbox">
              <input
                checked={consent}
                  onChange={(e) => {
                    e.target.checked
                      ? setConsent(true)
                      : setConsent(false);
                  }}
                  className="rounded-md"
                  type="checkbox"
                />
                <p onClick={() => {
                    consent?setConsent(false):setConsent(true)
                  }} className="text-textGray cursor-pointer select-none text-xs italic required">
                  I hereby agree and permit the Bank to use my personal
                  information (including sensitive personal data) and to share
                  the same with statutory/regulatory/law enforcement authorities
                  and payment networks, for monitoring and/or reporting
                  purposes.”
                </p>
              </div>
            </Form.Item>{" "}
            {response && (
              <div className="border border-dashed  border-primary  p-2 font-semibold mt-4">
                <p>
                  Account Balance :{" "}
                  {parseFloat(response.balanceAmount / 100).toFixed(2) ?? 0} Rs
                </p>
              </div>
            )}
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {openReceiptDrawer && (
        <ReportDrawer
          title={"Balance Receipt"}
          open={openReceiptDrawer}
          setOpen={setOpemReceiptDrawer}
          foryes={true}
          content={<Content />}
        />
      )}
    </>
  );
};

export default YesBalanceEnquiry;
