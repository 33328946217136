import { Drawer, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { HotelRoom } from "./Hotelfun";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaSmoking } from "react-icons/fa6";
import { LiaSmokingBanSolid } from "react-icons/lia";

const RoomsDrawer = ({
  hotelCode,
  hotelName,
  trace_id,
  resultIndex,
  isOpen,
  setIsOpen,
  roomguestArr,
}) => {
  const [details, setDetails] = useState(null);
  const navigate = useNavigate();
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  console.log(JSON.parse(searchParam.get("Other")));
  const getRooms = () => {
    const payload = {
      hotel_code: hotelCode,
      trace_id: trace_id,
      result_index: resultIndex,
    };
    HotelRoom(payload)
      .then((res) => {
        if (res.status) {
          setDetails(res.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getRooms();
  }, []);

  const handleSelectRoom = (data, index) => {
    // let temp = [...selectedRoom];
    // if (temp.find((i) => i.roomIndex === data.roomIndex)) {
    //   temp = temp.filter((i) => i.roomIndex !== data.roomIndex);
    // } else {
    //   temp = [...temp, data];
    // }
    // setSelectedRoom(temp);
    const filter=details.roomCombinations.roomCombination.find(i=>i.roomIndex?.includes(data.roomIndex))
    const getAllCombination=filter?.roomIndex.map(i=>details?.hotelRoomsDetails?.filter((item)=>item.roomIndex===i))
    console.log(getAllCombination.flat())
    // setSelectedRoom([data])
    setSelectedRoom(getAllCombination?.flat())
  };
  const checkList = (data) => {
    const find = selectedRoom.find((i) => i.roomIndex === data.roomIndex);
    if (find) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Drawer
      width={550}
      open={isOpen}
      onClose={() =>
        setIsOpen({
          hotelid: null,
          open: false,
          resultIndex: null,
        })
      }
      title={<p className="text-white">Room Details</p>}
      headerStyle={{ background: "var(--primary)", color: "white" }}
    >
      {!details ? (
        <div className="mt-4">
          <div class="h-32 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-500 w-full "></div>
          <div class="h-12 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-500 w-full "></div>
          <div class="h-12 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-500 w-full "></div>
          <div class="h-12 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-500 w-full "></div>
        </div>
      ) : (
        <div className="relative overflow-scroll">
          {details?.hotelRoomsDetails?.map((i, index) => {
            return (
              <div
                key={index}
                className={`border-4 relative ${
                  checkList(i) ? "border-primary" : "border-gray-300"
                } relative  rounded-md p-3 ${
                  index === details.hotelRoomsDetails.length - 1
                    ? "mb-20"
                    : "mb-3"
                }`}
              >
                <p className="text-sm font-bold">{i?.roomTypeName}</p>
                <p className="text-xs font-semibold mt-1">
                  {i?.roomDescription}
                </p>
                {i.amenities.length > 0 && (
                  <div>
                    <p className="mt-1 font-semibold underline underline-offset-2">
                      Ameneties
                    </p>
                    {i.amenities?.map((i) => (
                      <p className="text-xs">{i}</p>
                    ))}
                  </div>
                )}
                <div className="flex justify-between items-center">
                  <p className="mt-1 font-semibold flex justify-start items-center gap-x-2 ">
                    Cancellation Policy{" "}
                    <Tooltip title={i.cancellationPolicy}>
                      <FaInfoCircle className="cursor-pointer" />
                    </Tooltip>
                  </p>
                  <p
                    className={`border-2 ${
                      i.smokingPreference === "NoPreference"
                        ? "border-red-500"
                        : "border-green-500"
                    }  rounded-full text-center flex justify-center items-center w-8 h-8`}
                  >
                    {i.smokingPreference !== "NoPreference" ? (
                      <FaSmoking size={18} />
                    ) : (
                      <LiaSmokingBanSolid />
                    )}{" "}
                  </p>
                </div>
                <div className=" mt-2">
                  <table className="min-w-full table-auto border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="border border-gray-300 px-4 py-2">
                          Charge
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Currency
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          From Date
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          To Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {i?.cancellationPolicies.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="border border-gray-300 px-4 py-2">
                            {item.charge}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {item.currency}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {dayjs(item.fromDate).format("YYYY-MM-DD")}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {dayjs(item.toDate).format("YYYY-MM-DD")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p className="mt-1 font-semibold flex justify-start items-center gap-x-2 ">
                  Day Rates{" "}
                </p>
                <div className=" mt-2">
                  <table className="min-w-full table-auto border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="border border-gray-300 px-4 py-2">
                          Date
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {i?.dayRates.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="border border-gray-300 px-4 py-2">
                            {dayjs(item.date).format("YYYY-MM-DD")}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {item.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {checkList(i) && (
                  <div className="absolute right-3 top-4">
                    <FaCheckCircle color="green" size={18} />
                  </div>
                )}
                <div className="flex justify-end items-center mt-2">
                  <p
                    onClick={() => handleSelectRoom(i, index)}
                    className="bg-primary text-white px-3 py-1 rounded-md cursor-pointer font-semibold"
                  >
                    Select Room
                  </p>
                </div>
              </div>
            );
          })}
          <div className="bg-white fixed bottom-0 w-[500px] m-auto h-20 flex justify-center items-center shadow-[0_-2px_10px_6px_#e5e5e5] ">
            <div className="flex flex-col justify-center items-end w-full">
              <button
                onClick={() => {
                  selectedRoom?.length > 0 &&
                    navigate("/hotel-book", {
                      state: {
                        hotelCode,
                        hotelName,
                        selectedRoom,trace_id,resultIndex,
                        roomguestArr:roomguestArr,
                        other: JSON.parse(searchParam.get("Other")),
                      },
                    });
                }}
                className="bg-primary text-white  p-2  mt-3 rounded-sm w-3/4 m-auto"
              >
                Book
              </button>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default RoomsDrawer;
