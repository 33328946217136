export const HotelEndpoint = {
    getCountryList: "Api/V1/TravelHotel/getCountryList",
    getDestination: "Api/V1/TravelHotel/getDestinations",
    topDestination: "Api/V1/TravelHotel/topDestinations",
    getHotelStaticData: "Api/V1/TravelHotel/getHotelStaticData",
    hotelSearch: "Api/V1/TravelHotel/hotelSearch",
    hotelInfo: "Api/V1/TravelHotel/hotelInfo",
    hotelRoom: "Api/V1/TravelHotel/hotelRoom",
    hotelBlockRoom: "Api/V1/TravelHotel/hotelBlockRoom",
    hotelBookRoom: "Api/V1/TravelHotel/hotelBookRoom",
    hotelBookingDetail: "Api/V1/TravelHotel/getBookingDetails",
    hotelReport:"Api/V1/TravelHotel/hotelReport",
    hotelSendCancelReq:"Api/V1/TravelHotel/sendCancelRequest",
    hotelGetChangeReq:"Api/V1/TravelHotel/getChangeRequestStatus",
    ticketPrint:"/auth/hotelTicketPrint?booking_id="
  };
  