import { Button, DatePicker, Form, Radio, Select, Skeleton, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { BsBusFrontFill } from "react-icons/bs";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import Tabbar from "../Tabbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {  filterOption } from "../../../../Utils";
import dayjs from "dayjs";
import { busLayout, busSearch, getBusCityLists } from "./BusFun";
import BusDetailDrawer from "./BusDetailDrawer";
import SelectSeatDrawer from "./SelectSeatDrawer";

const Busticket = () => {
  const [bookingType, setBookingType] = useState(1);
  const [citiesList, setCitiesList] = useState([]);
  const [busResult, setBusResult] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const[openDetailDrawer,setOpenDetailDrawer]=useState(false)
  const[selectSeatDrawer,setSeatSelectDrawer]=useState(false)
const[points,setPoints]=useState(null)
  const[seatLayout,setSeatLayout]=useState(null)
  const[selectedBus,setSelectedBus]=useState({
    resultIndex:null,
    traceId:null,
    data:null
  })
  console.log(selectedBus)
  const [chooseReturn, setChooseReturn] = useState(false);
  const [cityDetail, setCityDetail] = useState({
    originId: null,
    destinationId: null,
    date_of_journey: null,
  });
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const navigate = useNavigate();
  useEffect(() => {
    if (!memberServices.includes("13")) {
      navigate("/not-Available");
    }
  }, []);
  const getCities = () => {
    getBusCityLists()
      .then((res) => {
        if (res.status) {
          setCitiesList(
            res.data.bus_cities.map((i, index) => {
              return {
                label: i.cityName,
                value: i.cityId,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getCities();
  }, []);

  const handleSearch = (val) => {
    navigate(`/bus/${val.origin_id}/${val.destination_id}/${dayjs(val.date_of_journey).format("YYYY-MM-DD")}`)
    setShowSkeleton(true);
    const formdata = new FormData();
    formdata.append("destination_id", val.destination_id);
    formdata.append("origin_id", val.origin_id);
    formdata.append(
      "date_of_journey",
      val.date_of_journey ? dayjs(val.date_of_journey).format("YYYY-MM-DD") : ""
    );
    console.log(val);
    busSearch(formdata)
      .then((res) => {
        if (res.status) {
          setSelectedBus({...selectedBus,traceId:res.data.traceId})
          setBusResult(res.data.busResults);
          setPoints({Origin:res.data.origin,Destination:res.data.destination})
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setShowSkeleton(false));
  };
  return (
    <>
      <Tabbar from={"bus"} />
    <Spin spinning={showSkeleton}>
    <div className=" p-4 bg-white rounded-xl">
        <div className="flex justify-start items-center gap-3">
          <BsBusFrontFill className="text-primary" size={20} />
          <p className="font-semibold text-xl">Bus Booking</p>
        </div>
        {/* <div className="grid grid-cols-2 place-items-start mt-3">
          <div className="radio-group ">
            <Radio.Group
              value={bookingType}
              onChange={(e) => setBookingType(e.target.value)}
              name="radiogroup"
              defaultValue={1}
            >
              <Radio value={1}>One Way</Radio>
            </Radio.Group>
          </div>
        </div> */}
        <Form
          onFinish={(val) => handleSearch(val)}
          onFinishFailed={(err) => console.log(err)}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-6 mt-4">
            <div className="flex justify-around items-center font-semibold w-full gap-4">
              <div className="w-full">
                <Form.Item
                  name={"origin_id"}
                  rules={[
                    {
                      required: true,
                      message: "Origin location is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder="From 🚌"
                    className="w-full mt-2"
                    options={citiesList}
                  />
                </Form.Item>
              </div>
              <HiOutlineSwitchHorizontal
                className="cursor-pointer text-primary font-extralight mt-4"
                size={40}
              />
              <div className="w-full">
                <Form.Item
                  name="destination_id"
                  rules={[
                    {
                      required: true,
                      message: "Destination location is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder="To 🚌"
                    className="w-full mt-2"
                    options={citiesList}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex justify-around items-center font-semibold w-full gap-4">
              <div className="w-full">
                <Form.Item
                  name={"date_of_journey"}
                  rules={[
                    {
                      required: true,
                      message: "Date of journey is required.",
                    },
                  ]}
                >
                  <DatePicker
                  disabledDate={(current)=>current && current < dayjs().startOf('day')}
                    popupClassName="bottom-calendar"
                    rootClassName="datepicker"
                    className="w-full datepicker mt-2"
                    placeholder="Depart"
                  />
                </Form.Item>
              </div>
              {/* <div className="w-full cursor-pointer">
              {chooseReturn ? (
                <DatePicker
                  popupClassName="bottom-calendar"
                  className="w-full datepicker mt-2"
                  onChange={() => {}}
                  placeholder="Return"
                />
              ) : (
                <div className="border border-bsgray400 h-8 mt-2  text-bsgray flex justify-around items-center">
                  <p>Return</p>
                  <p
                    onClick={() => {
                      setChooseReturn(true);
                      setBookingType(2);
                    }}
                  >
                    Choose Date
                  </p>
                </div>
              )}
            </div> */}
            </div>
          </div>
          <Form.Item>
            <div className="flex justify-end items-center">
              <Button
                htmlType="submit"
                style={{ color: "white" }}
                className="bg-primary hover:border-none border-none"
              >
                Search
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      {showSkeleton ? (
        <div className="mt-4">
         <div class="h-14 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-14 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-14 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-14 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
        </div>
      ) : (
        busResult.length !== 0 &&
        busResult.map((i, index) => (
          <>          <div className=" p-4 mt-4 rounded-lg bg-white ">
            <div
              key={index}
              className="grid grid-cols-3 place-items-center place-content-start"
            >
              <div className="flex flex-col w-full justify-end items-start">
                <p className="font-semibold text-lg">{i.travelName}</p>
                <p className="text-gray-700">{i.busType}</p>
              </div>
              <div className="flex justify-around w-full items-center">
                <p className="text-gray-900 font-semibold">
                  {new Date(i.departureTime).toLocaleTimeString()}
                </p>
                <p className="text-gray-400">--------</p>
                <p className="text-gray-900 font-semibold">
                {new Date(i.arrivalTime).toLocaleTimeString()}
                </p>
              </div>
              <div className="flex justify-end items-center w-full">
                <p className="font-semibold text-lg">₹ {i?.busPrice?.publishedPrice}</p>
              </div>
            </div>
            <div className="w-full h-[1px] bg-gray-300 mt-4"></div>
            <div className="flex justify-end mt-2 items-center">
              <p>
                {i.availableSeats} Seats Left |{" "}
                <span className="text-green-600 font-semibold">{i.maxSeatsPerTicket} Seats Per Ticket</span>
              </p>
            </div>
            <div className="flex justify-end mt-2 gap-3 items-center">
             <Button onClick={()=>{
              setSeatSelectDrawer(true)
              setSelectedBus({...selectedBus,resultIndex:i.resultIndex,data:[i]})
             }} style={{color:"white"}} className="bg-primary border-none hover:border-none font-semibold">Select Seats</Button>
             <Button onClick={()=>{
               setOpenDetailDrawer(true)
              setSelectedBus({...selectedBus,resultIndex:i.resultIndex,data:[i]})
             }} style={{color:"white"}} className="bg-primary border-none hover:border-none font-semibold">Bus Detail</Button>

            </div>
          </div>
          </>
        ))
      )}
    </Spin>
      {openDetailDrawer && <BusDetailDrawer open={openDetailDrawer} setOpen={setOpenDetailDrawer} detail={selectedBus}/>}
      {selectSeatDrawer && <SelectSeatDrawer open={selectSeatDrawer} setOpen={setSeatSelectDrawer} detail={selectedBus} points={points}/>}

    </>
  );
};

export default Busticket;
