import { Button, Card, Form, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  createDynamicArray,
  dispatchtoast,
  emailPattern,
  getLocalStorageItem,
  mobilePattern,
} from "../../../../Utils";
import CommonInput from "../../../../Common/CommonInput";
import { useLocation, useNavigate } from "react-router-dom";
import { HotelBlock, HotelBook } from "./Hotelfun";

const HotelBookPage = () => {
  const [form] = Form.useForm();
  const {
    hotelCode,
    hotelName,
    trace_id,
    resultIndex,
    selectedRoom,
    other,
    roomguestArr,
  } = useLocation().state || {};
  const navigate=useNavigate()
  const [formLoader, setFormLoader] = useState(false);


  useEffect(()=>{
    const items = roomguestArr.map((room) => {
      const list = [];
    
      // Add adults (paxType: 1)
      for (let i = 0; i < room.noAdult; i++) {
        list.push({ PaxType: 1 });
      }
    
      // Add children (paxType: 2)
      for (let i = 0; i < room.noChild; i++) {
        list.push({ PaxType: 2 });
      }
    
      return { list };
    });
    form.setFieldsValue({"items":items})
    console.log(items);
  },[])
  const handleHotelBlock = (val) => {
    // const AtleastOneISleadPAx = val.fields.find((i) => i.IsLeadPax);
    // if (!AtleastOneISleadPAx) {
    //   dispatchtoast(
    //     "Atleast select one leadpax from passenger from adults",
    //     true
    //   );
    //   return;
    // } else {
    setFormLoader(true);
    const payload = {
      ResultIndex: resultIndex,
      HotelCode: hotelCode,
      HotelName: hotelName,
      GuestNationality: "IN",
      NoOfRooms: other?.rooms,
      IsVoucherBooking: true,
      HotelRoomsDetails: selectedRoom?.map((i) => {
        return {
          roomIndex: i?.roomIndex,
          roomTypeCode: i?.roomTypeCode,
          roomTypeName: i?.roomTypeName,
          ratePlanCode: i?.ratePlanCode,
          SmokingPreference: "0",
          Price: i?.price,
        };
      }),
      TraceId: trace_id,
      DepartureTransport: {
        TransportInfoId: "0",
        Time: "2024-09-20T00:00:00+05:30",
      },
      ArrivalTransport: {
        ArrivalTransportType: 0,
        TransportInfoId: "today",
        Time: "2024-09-20T00:00:00+05:30",
      },
    };
    HotelBlock(payload)
      .then((res) => {
        if (res.status) {
          const payload = {
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            ResultIndex: resultIndex,
            HotelCode: hotelCode,
            HotelName: hotelName,
            GuestNationality: "IN",
            NoOfRooms: other?.rooms,
            IsVoucherBooking: true,
            HotelRoomsDetails: selectedRoom?.map((room, roomIndex) => {
              // Filter passengers into adults and children

              return {
                roomIndex: room?.roomIndex,
                roomTypeCode: room?.roomTypeCode,
                roomTypeName: room?.roomTypeName,
                ratePlanCode: room?.ratePlanCode,
                SmokingPreference: "0",
                Price: room?.price,
                HotelPassenger: val.items[roomIndex]?.list.map((i, index) => {                  
                    return {
                      Age: i.Age,
                      FirstName: i.FirstName,
                      LastName: i.LastName,
                      LeadPassenger: index===0?true:false, // Set LeadPassenger to true only for the first adult
                      Middlename: i.MiddleMame,
                      Email: i.Email,
                      Phoneno: i.Phoneno,
                      PaxType: i?.PaxType,
                      Title: i?.Title,
                    };
    
                }),
              };
            }),
            TraceId: trace_id,
            
          };

          console.log({ payload });
          HotelBook(payload)
            .then((res) => {
              if(res.status){
                dispatchtoast(res.message)
                navigate("/Report/hotel")
              }
            })
            .catch((err) => console.log(err))
            .finally(() => setFormLoader(false));
        }
      })
      .catch((err) => {
        setFormLoader(false);
        console.log(err);
      });
    // }
  };

  return (
    <>
      <div className="grid grid-cols-7 place-items-start gap-x-3">
        <div className="col-span-7 md:col-span-4 w-full">
          <Spin spinning={formLoader}>
            <div className="bg-white max-h-[80vh] overflow-scroll rounded-lg p-3">
              <p className="text-red-500 font-semibold font-serif">
                Note: Each Room should have atleast one adult. | Provide each room any adult phone no and email.
              </p>
              <p className="font-bold">Enter Passanger Detail</p>
              <Form
                onFinish={(val) => {
                  handleHotelBlock(val);
                  console.log(val);
                }}
                form={form}
                onFinishFailed={(err) => {
                  console.log(err);
                }}
                name="dynamic_form_complex"
                autoComplete="off"
                initialValues={{
                  items: roomguestArr.map((room) => ({
                    list: createDynamicArray(room.noAdult + room.noChild, 1), // Set based on adults and children
                  })),
                }}
              >
                <Form.List name="items">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map((field, index) => (
                        <Card
                          size="small"
                          title={`Room ${index + 1}`}
                          key={field.key}
                          className="mt-4"
                        >
                          <Form.Item>
                            <Form.List name={[field.name, "list"]}>
                              {(subFields, subOpt) => (
                                <div>
                                  {subFields.map((subField, subIndex) => (
                                    <div key={subField.key}>
                                      {/* <Form.Item
                                        className="w-full"
                                        labelCol={{ span: 24 }}
                                        name={[subField.name, "IsLeadPax"]}
                                        fieldKey={[name, "IsLeadPax"]}
                                        valuePropName="checked"
                                      >
                                        <Checkbox
                                          checked={form.getFieldValue([
                                            "fields",
                                            subField.name,
                                            "IsLeadPax",
                                          ])}
                                          onChange={() =>
                                            onCheckboxChange(index)
                                          }
                                        >
                                          Main Member
                                        </Checkbox>
                                      </Form.Item> */}
                                      <p className="font-semibold">Guest {subIndex+1}</p>
                                      <div className="grid grid-cols-1 sm:grid-cols-3  gap-x-4 mt-2">
                                        <Form.Item
                                          rules={[
                                            {
                                              required: true,
                                              message: "Select Type.",
                                            },
                                          ]}
                                          className="w-full"
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "PaxType"]}
                                          fieldKey={[subField.name, "PaxType"]}
                                          label="Passanger Type"
                                        >
                                          <Select
                                          disabled
                                            options={[
                                              {
                                                label: "Adult",
                                                value: 1,
                                              },
                                              {
                                                label: "Child",
                                                value: 2,
                                              },
                                            ]}
                                            placeholder="Select Type"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: "Select Title.",
                                            },
                                          ]}
                                          className="w-full"
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "Title"]}
                                          fieldKey={[subField.name, "Title"]}
                                          label="Title"
                                        >
                                          <Select
                                            options={[
                                              {
                                                label: "Mr",
                                                value: "Mr",
                                              },
                                              {
                                                label: "Ms",
                                                value: "Ms",
                                              },
                                              {
                                                label: "Mrs.",
                                                value: "Mrs",
                                              },
                                            ]}
                                            placeholder="Select Title"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: "Enter First Name.",
                                            },
                                          ]}
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "FirstName"]}
                                          label="FirstName"
                                        >
                                          <CommonInput
                                            placeholder={"Enter First Name"}
                                          />
                                        </Form.Item>{" "}
                                        <Form.Item
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "MiddleName"]}
                                          label="MiddleName"
                                        >
                                          <CommonInput
                                            placeholder={"Enter Middle Name"}
                                          />
                                        </Form.Item>{" "}
                                        <Form.Item
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: "Enter LastName.",
                                            },
                                          ]}
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "LastName"]}
                                          label="LastName"
                                        >
                                          <CommonInput
                                            placeholder={"Enter Last Name"}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: "Enter Age.",
                                            },
                                          ]}
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "Age"]}
                                          label="Age"
                                        >
                                          <CommonInput
                                            placeholder={"Enter Age"}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          rules={[
                                            {
                                              pattern: mobilePattern,
                                              message: "Enter valid Phone no.",
                                            },
                                          ]}
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "Phoneno"]}
                                          label="Phoneno"
                                        >
                                          <CommonInput
                                            placeholder={"Enter Phone"}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          rules={[
                                            {
                                              pattern: emailPattern,
                                              message: "Enter valid Email",
                                            },
                                          ]}
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "Email"]}
                                          label="Email"
                                        >
                                          <CommonInput
                                            placeholder={"Enter Email"}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          rules={[
                                            {
                                              required: true,
                                              message: "Select Gender.",
                                            },
                                          ]}
                                          labelCol={{ span: 24 }}
                                          name={[subField.name, "Gender"]}
                                          label="Gender"
                                        >
                                          <Select
                                            options={[
                                              {
                                                label: "Male",
                                                value: 1,
                                              },
                                              {
                                                label: "Female",
                                                value: 2,
                                              },
                                            ]}
                                            placeholder={"Select Gender"}
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </Form.List>
                          </Form.Item>
                        </Card>
                      ))}
                    </div>
                  )}
                </Form.List>
                <Form.Item className="w-full">
                  <Button
                    // loading={FormSpinner}
                    style={{ color: "white" }}
                    className="w-full mt-2 bg-primary text-white"
                    htmlType="submit"
                  >
                    Book
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Spin>
        </div>
        <div className="col-span-7 mt-2 md:mt-0 w-full md:col-span-3">
          <div className="bg-white  max-h-[80vh] overflow-scroll rounded-lg p-3">
            <p className="font-bold text-sm rounded-sm mt-2 bg-primary text-white px-4 py-2">
              Fare Summary
            </p>
            <p className="font-medium text-xs rounded-sm mt-2 text-gray-500 ">
              Rooms | {other.rooms ?? 0} , ADULTS | {other?.adults ?? 0} ,
              CHILDS | {other?.children ?? 0}
            </p>
            <div className="p-3">
              <p className="font-semibold text-gray-400">
                {/* Passengers: {selectedSeat?.length??""} */}
              </p>
              <hr className="mt-2" />
              <div className="mt-2 flex justify-between items-center">
                <p className="w-full text-lg text-primary font-bold">
                  Grand Total:
                </p>
                <p className="w-full text-primary font-bold  text-2xl text-end">
                  ₹{" "}
                  {selectedRoom?.reduce((a, b) => {
                    a = a + b.price?.publishedPrice;
                    return a;
                  }, 0).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white mt-4  max-h-[80vh] overflow-scroll rounded-lg p-3">
            <p className="font-bold text-sm rounded-sm mt-2 bg-primary text-white px-4 py-2">
              Room Summary
            </p>
            <p className="font-bold text-lg mt-2">{hotelName}</p>
            {selectedRoom?.slice(0, 1)?.map((i, index) => {
              return (
                <div className="border border-gray-300 p-1 rounded-md mt-2">
                  <div className="p-3">
                    <p className="font-bold">
                      {other?.rooms} X {i.roomTypeName}
                    </p>
                    <p className="font-semibold mt-1">{i.roomDescription}</p>
                    <p className="font-semibold mt-1">{i.ratePlanName}</p>
                  </div>
                  <hr className="mt-2" />
                  <div className="p-3">
                    <p className="font-semibold mt-1">
                      Last Cancellation Date:{" "}
                      {new Date(i?.lastCancellationDate).toLocaleString()}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelBookPage;
